import React, { useCallback, useEffect, useState } from 'react';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import { useDropzone } from 'react-dropzone';
import { ModalComponentProps } from '@core/modals/modals.interface';
import I18n from '@components/I18n';
import styles from './CreateARProjectModal.scss';
import Icon from '@components/Icon';
import classNames from 'classnames';
import { createARProjectService } from './CreateARProjectService';
import { httpService } from '@core/http/HttpService';
import { i18nService } from '@core/i18n/I18nService';
import Button from '@components/Button';
import history from '@core/history';
import {
  saveCreateARProject,
  saveUnitsAndUpVectorForARProject,
  unitsOptions,
  upViewImages,
  validateARProjectName,
} from './CreateARProjectModal.utils';
import Select from '@components/Select';
import { modalService } from '@core/modals/ModalService';
import { augmentedRealityUploadService } from '@core/AugmentedReailtyUploadService';
import { AR_PROJECT_NAME_MAX_LENGTH } from '@core/constants';
import { ARGlbFile, ARModelDimensions } from '@core/http/server.interface';
import UpViewModelViewer from './UpViewModelViewer';
import { unitConverter } from '@core/utils';
import { dispatch } from '@src/redux/store';
import { decrementBusyCounter, incrementBusyCounter } from '@src/redux/config';

const SUPPORTED_FORMATS = ['.stp', '.step'];
const DIMENSIONS_DECIMAL_DIGITS = 3;
const MAX_MODEL_DIMENSION_IN_MM = 15_000.0;

const validationSchema = Yup.object().shape({
  name: Yup.string()
    .required('Project name is required')
    .test(
      'checkDuplicateName',
      i18nService.translate('augmented-reality.project-name-not-valid'),
      async (value) => {
        if (!value) return false;
        return await validateARProjectName(value);
      }
    ),
});

const initialScopeState = {
  assetType: null,
};

function CreateARProjectModal(props: ModalComponentProps) {
  const { dismiss, args } = props;
  const cancel = useCallback(() => {
    dismiss(false);
  }, [dismiss]);

  const [assetTypeOptions, setAssetTypeOptions] = useState([]);
  const [assetTypeSelected, setAssetTypeSelected] = useState(initialScopeState.assetType);
  const [unitsSelected, setUnitsSelected] = useState(unitsOptions[2]);
  const [fileError, setFileError] = useState('');
  const [fileUploading, setFileUploading] = useState(false);
  const [fileUploadingName, setFileUploadingName] = useState('');
  const [taskId, setTaskId] = useState<number>();
  const [modelDimensions, setModelDimensions] = useState<ARModelDimensions>(null);
  const [file, setFile] = useState<ARGlbFile>(null);
  const [upVectorSelected, setUpVectorSelected] = useState(null);
  const [showBusyIndicator, setShowBusyIndicator] = useState(false);

  const [initialValues, setInitialValues] = useState({
    name: '',
    assetType: '',
    file: null,
  });

  const onNewProjectCreated = (newARProjectResponse) => {
    console.log(newARProjectResponse);
    history.push(`/main/augmented-reality/${newARProjectResponse.id}/true`);
  };

  const onSubmit = async (values) => {
    const isSizeValid =
      unitsSelected &&
      !Object.values(modelDimensions).some(
        (md) => unitConverter(md, unitsSelected.value, 'MM') > MAX_MODEL_DIMENSION_IN_MM
      );

    if (isSizeValid) {
      const result = await modalService.openModal('confirm', {
        headerText: 'augmented-reality.modal-import-file-header',
        text: 'augmented-reality.will-take-time',
        iconType: 'attention_image',
        confirmText: 'general.confirm',
        cancelText: 'general.cancel',
        showCloseBtn: true,
      });
      // 1. Save units to BE
      if (result.res) {
        await saveUnitsAndUpVectorForARProject(unitsSelected.value, upVectorSelected, taskId);

        // 2. Renew polling and save the project when status is COMPLETED
        await augmentedRealityUploadService.startPolling({
          getUploadType: 'uploadARModelFile',
          taskId,
          callback: (arModelId) => {
            saveCreateARProject(values, assetTypeSelected, arModelId, dismiss, onNewProjectCreated);
            setFileUploading(false);
          },
          errorCallback: (error) => {
            setFile(null);
            setFileUploading(false);
            setFileUploadingName('');
            setUpVectorSelected(null);
            dispatch(decrementBusyCounter());
            modalService.openModal('alert', {
              text: i18nService.translate(`errors.${42}`) + ' Status: ' + error,
              iconType: 'attention_image',
              showCloseBtn: true,
              headerText: `errors.${42}-header`,
            });
          },
          isCreate: true,
        });
      }
    } else {
      modalService.openModal('confirm', {
        headerText: 'augmented-reality.file-size-invalid.header',
        text: 'augmented-reality.file-size-invalid.text',
        maxDimension: unitConverter(MAX_MODEL_DIMENSION_IN_MM, 'MM', 'M'),
        iconType: 'attention_image',
        confirmText: 'general.close',
        showCloseBtn: true,
      });
    }
  };

  const getAssetTypes = (value?: any) => {
    httpService
      .api({
        type: 'getAssetTypeOptionsInfo',
        query: {
          search: value,
          p: 1,
          ps: 1000,
        },
      })
      .then((types: any) => {
        setAssetTypeOptions(types.results);
      });
  };

  const handleChange = (target, value) => {
    switch (target) {
      case 'assetType':
        setAssetTypeSelected(value);
        break;
      case 'units':
        setUnitsSelected(value);
        break;
    }
  };

  const fetchDefaultName = useCallback(async () => {
    try {
      await httpService
        .api({
          type: 'getNewArProjectDefaultName',
        })
        .then((result: any) => {
          setInitialValues((prevValues) => ({ ...prevValues, name: result.name }));
        });
    } catch (error) {
      console.error('Error fetching default name', error);
    }
  }, []);

  useEffect(() => {
    createARProjectService.setModalDismiss(dismiss);
    fetchDefaultName();
    setFileUploadingName('');
    getAssetTypes();
  }, [fetchDefaultName]);

  const onDropRejected = useCallback(async () => {
    await modalService.openModal('confirm', {
      headerText: 'augmented-reality.format-not-supported.header',
      text: 'augmented-reality.format-not-supported.text',
      iconType: 'attention_image',
      confirmText: 'general.close',
      showCloseBtn: true,
    });
  }, []);

  const onDrop = useCallback(async (acceptedFiles) => {
    setShowBusyIndicator(true);
    if (acceptedFiles.length > 0) {
      const isConfirmed = await modalService.openModal('confirm', {
        text: 'augmented-reality.modal-import-file',
        iconType: 'attention_image',
        confirmText: 'general.confirm',
        cancelText: 'general.cancel',
        headerText: 'augmented-reality.modal-import-file-header',
        showCloseBtn: true,
      });
      if (isConfirmed) {
        dispatch(incrementBusyCounter());
        const file = acceptedFiles[0];
        setFile(null);
        setUpVectorSelected(null);
        setFileError('');
        setFileUploadingName(acceptedFiles[0].name);
        setFileUploading(true);
        try {
          await augmentedRealityUploadService.upload({
            getUploadType: 'uploadARModelFile',
            file,
            fileName: file.name,
            callback: (response) => {
              setModelDimensions(response.dimensions);
              setFile(response.glbFile);
              setFileUploading(false);
            },
            errorCallback: (error) => {
              console.error('Error uploading file', error);
              dispatch(decrementBusyCounter());
              modalService.openModal('alert', {
                text: i18nService.translate(`errors.${42}`) + ' Status: ' + error,
                iconType: 'attention_image',
                showCloseBtn: true,
                headerText: `errors.${42}-header`,
              });
              setFileUploadingName('');
              setFileError('File upload failed');
              setFileUploading(false);
            },
            setTaskId,
          });
          setShowBusyIndicator(false);
        } catch (error) {
          console.error('Error uploading file', error);
          setFileUploadingName('');
          setFileError('File upload failed');
          setFileUploading(false);
        }
      }
    }
  }, []);

  const { open, getRootProps, getInputProps } = useDropzone({
    onDrop,
    onDropRejected,
    accept: SUPPORTED_FORMATS.join(','),
    noClick: true,
    noKeyboard: true,
  });

  return (
    <div className={styles.wrapper}>
      <div className={'modalHeader'}>
        <I18n>augmented-reality.create-modal-title</I18n>
        <Icon type="close" onClick={cancel} className={'pointer'}></Icon>
      </div>
      <div className={styles.modalContent}>
        <Formik
          initialValues={initialValues}
          enableReinitialize
          validationSchema={validationSchema}
          onSubmit={onSubmit}>
          {({ isValid, values, dirty }) => (
            <Form>
              <div className={styles.formWrapper}>
                <div className={styles.topSectionContainer}>
                  <div className={styles.halfSectionContainer}>
                    <div className={styles.fieldWrapper}>
                      <div className={classNames(styles.fieldLabel)}>
                        <I18n>augmented-reality.project-name</I18n>
                      </div>
                      <Field
                        name="name"
                        type="text"
                        className={styles.input}
                        maxLength={AR_PROJECT_NAME_MAX_LENGTH}
                      />
                    </div>
                    <ErrorMessage name="name" component="div" className={styles.error} />
                    <div className={styles.fieldWrapper}>
                      <div className={classNames(styles.fieldLabel, 'ellipsis-overflow')}>
                        <I18n>create-dashboard-modal.assettype</I18n>
                      </div>
                      <Select
                        id="assetType"
                        options={assetTypeOptions}
                        getOptionLabel={(option) => option.name}
                        getOptionValue={(option) => option.id}
                        values={assetTypeSelected}
                        onChange={(filters) => handleChange('assetType', filters)}
                        tableConfig={(options) => {
                          return {
                            name: options.name,
                            label:
                              options.labels && options.labels.map((item) => item.text).join(', '),
                          };
                        }}
                        optionType="tableWithCheckbox"
                        placeholder={i18nService.translate('general.select-asset-type')}
                      />
                    </div>
                    <ErrorMessage name="assetType" component="div" className={styles.error} />
                  </div>
                  <div className={styles.halfSectionContainer}>
                    <div className={styles.threeDModelHeader}>
                      <I18n className={classNames(styles.sectionLabel, 'ellipsis-overflow')}>
                        augmented-reality.modal-3DModel-label
                      </I18n>
                      <Button type="button" onClick={open}>
                        <I18n>general.browse</I18n>
                      </Button>
                    </div>
                    <div className={styles.fileContentWrapper}>
                      <div {...getRootProps({ className: styles.dropZone })} onClick={open}>
                        <input {...getInputProps()} />
                        {fileUploading
                          ? i18nService.translate('augmented-reality.modal-upload-file-progress')
                          : fileError
                          ? i18nService.translate(`errors.${42}`)
                          : fileUploadingName
                          ? fileUploadingName
                          : i18nService.translate('augmented-reality.modal-upload-file-area')}
                      </div>
                      <I18n element={'div'} className={styles.supportedFormatsText} noEllipsis>
                        augmented-reality.modal-supported-formats
                      </I18n>
                      <ErrorMessage name="file" component="div" className={styles.error} />
                    </div>
                  </div>
                </div>
                <div className={styles.sectionWrapper}>
                  <div className={classNames(styles.sectionLabel, 'ellipsis-overflow')}>
                    <I18n>augmented-reality.model-dimensions</I18n>
                  </div>
                  <div className={styles.dimensionsContentWrapper}>
                    <div className={styles.sectionRow}>
                      <I18n>general.units</I18n>
                      <Select
                        styles={{
                          container: { width: '200px' },
                          menuPortal: { zIndex: 9999999999999 },
                        }}
                        maxMenuHeight={180}
                        menuPortalTarget={document.getElementById('mainContent') || document.body}
                        disabled={!fileUploadingName || fileUploading}
                        options={unitsOptions}
                        value={unitsSelected}
                        onChange={(option) => handleChange('units', option)}
                      />
                    </div>
                    <div className={styles.sectionRow}>
                      <I18n>general.dimensions</I18n>
                      {!fileUploadingName || fileUploading ? (
                        <I18n>general.no-file-selected</I18n>
                      ) : modelDimensions ? (
                        `${modelDimensions.w.toFixed(
                          DIMENSIONS_DECIMAL_DIGITS
                        )} ${i18nService.translate(
                          `units-of-measurement.${unitsSelected.value.toLowerCase()}`
                        )} x ${modelDimensions.h.toFixed(
                          DIMENSIONS_DECIMAL_DIGITS
                        )} ${i18nService.translate(
                          `units-of-measurement.${unitsSelected.value.toLowerCase()}`
                        )} x ${modelDimensions.d.toFixed(
                          DIMENSIONS_DECIMAL_DIGITS
                        )} ${i18nService.translate(
                          `units-of-measurement.${unitsSelected.value.toLowerCase()}`
                        )}`
                      ) : (
                        ''
                      )}
                    </div>
                  </div>
                </div>
                <div className={classNames(styles.sectionWrapper, styles.upViewSectionWrapper)}>
                  <I18n className={styles.sectionLabel}>augmented-reailty.up-view.header</I18n>
                  <div className={styles.textDescription}>
                    <I18n>augmented-reailty.up-view.description</I18n>
                  </div>
                  <div className={styles.upView}>
                    {upViewImages.map((option, index) => (
                      <div
                        key={index}
                        className={classNames(
                          styles.upViewItem,
                          file?.url ? styles.enabled : styles.disabled,
                          upVectorSelected === option.value ? styles.selected : ''
                        )}
                        onClick={file?.url ? () => setUpVectorSelected(option.value) : null}>
                        {file?.url ? (
                          <UpViewModelViewer
                            key={index}
                            index={index}
                            modelPath={file?.url}
                            upVector={option.upVector}
                            position={option.position}
                            rotation={option.rotation}
                            showBusyIndicator={showBusyIndicator}
                          />
                        ) : (
                          <I18n>general.no-file-selected</I18n>
                        )}
                      </div>
                    ))}
                  </div>
                </div>
                <div className={styles.actionsRow}>
                  <div>
                    <Button
                      styles={{ marginLeft: 13, maxWidth: 250 }}
                      mode={['cancel', 'bigFont']}
                      onClick={cancel}>
                      <I18n>general.cancel</I18n>
                    </Button>
                    <Button
                      type="submit"
                      disabled={
                        (!isValid && dirty) ||
                        values.name.length < 2 ||
                        assetTypeSelected == null ||
                        fileUploading ||
                        fileUploadingName == '' ||
                        !upVectorSelected
                      }
                      styles={{ marginLeft: 13, maxWidth: 250 }}
                      mode="bigFont">
                      <I18n>general.create</I18n>
                    </Button>
                  </div>
                </div>
              </div>
            </Form>
          )}
        </Formik>
      </div>
    </div>
  );
}

export default CreateARProjectModal;
