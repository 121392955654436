import React, {
  useImperativeHandle,
  forwardRef,
  useState,
  useEffect,
  MutableRefObject,
  useRef,
  Suspense,
} from 'react';
import { useParams } from 'react-router-dom';
import { EffectComposer, SMAA } from '@react-three/postprocessing';
import { useThree, useFrame } from '@react-three/fiber';
import * as THREE from 'three';
import {
  Grid,
  CameraControls,
  GizmoHelper,
  GizmoViewport,
  PivotControls,
  useGLTF,
} from '@react-three/drei';
import {
  uploadScreenshot,
  roundToDecimals,
  updateProjectState,
  trimVector3,
  trimEuler,
} from './AugmentedRealityEditorPage.utils';
import { cssVarsService } from '@core/CssVarsService';
import SceneWidgetLabel from './SceneWidgetLabel';
import CanvasRaycaster from './CanvasRaycaster';
import { usePrevious } from '@core/hooks/usePrevious';
import { MIN_SIZE } from './SidePanelToolbar/SidePanelToolbar.utils';

export interface SceneContentProps {
  onLoad: (gl: any, scene: any, camera: any, canvas: any) => void;
  cameraControlsRef: MutableRefObject<any>;
  projectData: any;
  widgetData: any;
  widgetList: any;
  selectedWidgetId: any;
  handleWidgetClick: (widgetId) => void;
  handleWidgetPositionRotationChange: (position, rotation) => void;
  handleWidgetLookAtMeChange: (rotation) => void;
  OnShowWidgetList: () => void;
  resetTrigger: any;
  OnResetComplete: () => void;
}

export interface SceneContentRef {
  zoomIn: () => void;
  zoomOut: () => void;
  fitModelToScale: () => void;
  getVector3PositionFromDropSpace: (dropVector) => THREE.Vector3;
  getMaxModelDimension: () => number;
}

const SceneContent = forwardRef<SceneContentRef, SceneContentProps>(
  (
    {
      onLoad,
      cameraControlsRef,
      projectData,
      widgetData,
      widgetList,
      selectedWidgetId,
      handleWidgetClick,
      handleWidgetPositionRotationChange,
      handleWidgetLookAtMeChange,
      OnShowWidgetList,
      resetTrigger,
      OnResetComplete,
    },
    ref
  ) => {
    const { projectId, isNewProject } = useParams<any>();
    const { gl, scene, camera } = useThree();
    const raycaster = useRef(new THREE.Raycaster());
    const [loaded, setLoaded] = useState(false);
    const [screenshotTaken, setScreenshotTaken] = useState(false);
    const directionalLightRef = useRef(null);
    const [widgetRefs, setWidgetRefs] = useState({});
    const [matricesRefs, setMatricesRefs] = useState({});
    const { scene: gltf } = useGLTF(projectData.arModel.glbFile.url) as any;
    const [modelLoaded, setModelLoaded] = useState(false);
    const [position, setPosition] = useState(() => new THREE.Vector3());
    const [rotation, setRotation] = useState(() => new THREE.Euler());
    const boundingSphereRef = useRef<THREE.Sphere | null>(null);
    const prevStatesForm = widgetData?.statesForm
      ? usePrevious(widgetData.statesForm)
      : { w: MIN_SIZE, h: MIN_SIZE, x: 0, y: 0, z: 0 };

    const configureCameraListeners = () => {
      const controls = cameraControlsRef.current;
      if (controls) {
        controls.addEventListener('rest', handleCameraMovingEnd);
        return () => {
          controls.removeEventListener('rest', handleCameraMovingEnd);
        };
      }
    };

    const handleLookTo = (targetPosition) => {
      if (cameraControlsRef.current) {
        cameraControlsRef.current.setLookAt(
          Number(cameraControlsRef.current.camera.position.x),
          Number(cameraControlsRef.current.camera.position.y),
          Number(cameraControlsRef.current.camera.position.z),
          Number(targetPosition.x),
          Number(targetPosition.y),
          Number(targetPosition.z),
          true
        );
      }
    };

    const handleDrag = (lm, dm, wm, dwm, widgetId) => {
      setMatricesRefs((prev) => ({
        ...prev,
        [widgetId]: wm.clone(),
      }));
      const newPosition = new THREE.Vector3();
      newPosition.setFromMatrixPosition(wm);
      setPosition(trimVector3(newPosition));

      const newRotation = new THREE.Euler();
      newRotation.setFromRotationMatrix(wm);
      setRotation(trimEuler(newRotation));
    };

    const onPivotControlsDragEnd = () => {
      if (cameraControlsRef.current) {
        cameraControlsRef.current.enabled = true;
      }

      handleWidgetPositionRotationChange(trimVector3(position), trimEuler(rotation));
    };

    const handleLocalWidgetClick = (selectedWidgetData) => {
      const newPosition = new THREE.Vector3(
        selectedWidgetData.statesForm.x,
        selectedWidgetData.statesForm.y,
        selectedWidgetData.statesForm.z
      );
      setPosition(newPosition);

      const newRotation = new THREE.Euler(
        selectedWidgetData.customization.lookAtMe.x,
        selectedWidgetData.customization.lookAtMe.y,
        selectedWidgetData.customization.lookAtMe.z
      );
      setRotation(trimEuler(newRotation));
      updateMatrix(newPosition, newRotation, selectedWidgetData.i);
      handleWidgetClick(selectedWidgetData);
    };

    const calculateModelBoundingBoxDimension = () => {
      const boundingBox = new THREE.Box3().setFromObject(gltf);
      const size = boundingBox.getSize(new THREE.Vector3());
      const maxDim = Math.max(size.x, size.y, size.z);
      return maxDim;
    };

    const restoreCameraState = () => {
      if (projectData.state) {
        // Restore camera position
        if (projectData.state.position) {
          const position = projectData.state.position;
          cameraControlsRef.current.setPosition(position.x, position.y, position.z);
        } else {
          console.error('Project State position is undefined');
        }
        if (projectData.state.target) {
          const target = projectData.state.target;
          cameraControlsRef.current.setTarget(target.x, target.y, target.z);
        } else {
          console.error('Project State target is undefined');
        }
      } else {
        if (cameraControlsRef.current) {
          cameraControlsRef.current.fitToSphere(gltf, true);
        }
      }
    };

    const updateMatrix = (pos, rot, widgetId) => {
      const newMatrix = new THREE.Matrix4();
      const quaternion = new THREE.Quaternion();
      quaternion.setFromEuler(rot);
      newMatrix.compose(pos, quaternion, new THREE.Vector3(1, 1, 1));

      setMatricesRefs((prev) => ({
        ...prev,
        [widgetId]: newMatrix,
      }));
    };

    useEffect(() => {
      const { widgetRefs, matrices } = widgetList.reduce(
        (acc, widget) => {
          acc.widgetRefs[widget.id] = React.createRef();
          const matrix = new THREE.Matrix4();
          const position = new THREE.Vector3(
            widget.statesForm.x,
            widget.statesForm.y,
            widget.statesForm.z
          );
          const euler = new THREE.Euler(
            widget.customization.lookAtMe.x,
            widget.customization.lookAtMe.y,
            widget.customization.lookAtMe.z
          );
          const quaternion = new THREE.Quaternion();
          quaternion.setFromEuler(euler);
          const scale = new THREE.Vector3(1, 1, 1);
          matrix.compose(position, quaternion, scale);
          acc.matrices[widget.id] = matrix;

          return acc;
        },
        { pivotControlsRefs: {}, widgetRefs: {}, matrices: {} }
      );

      setWidgetRefs(widgetRefs);
      setMatricesRefs(matrices);
    }, [widgetList]);

    useEffect(() => {
      if (selectedWidgetId == null) return;
      const selectedWidgetObject = widgetList.find((item) => item.id === selectedWidgetId);
      if (selectedWidgetObject == null) return;
      if (selectedWidgetObject.statesForm) {
        const newPosition = new THREE.Vector3(
          selectedWidgetObject.statesForm.x,
          selectedWidgetObject.statesForm.y,
          selectedWidgetObject.statesForm.z
        );
        if (!selectedWidgetObject.onDrop) {
          handleLookTo(newPosition);
        } else {
          delete selectedWidgetObject.onDrop;
        }
      }
    }, [selectedWidgetId]);

    useEffect(() => {
      if (widgetData.id != selectedWidgetId) return;
      const newPosition = new THREE.Vector3(
        widgetData.statesForm.x === undefined ? prevStatesForm?.x : widgetData.statesForm.x,
        widgetData.statesForm.y === undefined ? prevStatesForm?.y : widgetData.statesForm.y,
        widgetData.statesForm.z === undefined ? prevStatesForm?.z : widgetData.statesForm.z
      );
      setPosition(trimVector3(newPosition));

      const newRotation = new THREE.Euler(
        widgetData.customization.lookAtMe.x,
        widgetData.customization.lookAtMe.y,
        widgetData.customization.lookAtMe.z
      );
      setRotation(trimEuler(newRotation));
      updateMatrix(newPosition, newRotation, widgetData.id);
    }, [widgetData]);

    useEffect(() => {
      const handleLoad = () => {
        restoreCameraState();
        configureCameraListeners();
        if (gltf) {
          setLoaded(true);
          setModelLoaded(true);
          const boundingBox = new THREE.Box3().setFromObject(gltf);
          const boundingSphere = new THREE.Sphere();
          boundingBox.getBoundingSphere(boundingSphere);
          boundingSphereRef.current = boundingSphere;
          onLoad(gl, scene, camera, gl.domElement);
        }
      };

      if (!loaded) {
        handleLoad();
      }
    }, [gl, scene, camera, cameraControlsRef, loaded, onLoad]);

    useEffect(() => {
      if (loaded && !screenshotTaken && isNewProject && modelLoaded) {
        const storedScreenshotKeysArray =
          JSON.parse(sessionStorage.getItem('ScreenshotTakenKeys')) || [];

        if (!storedScreenshotKeysArray.includes(String(projectId))) {
          storedScreenshotKeysArray.push(projectId);
          sessionStorage.setItem('ScreenshotTakenKeys', JSON.stringify(storedScreenshotKeysArray));

          console.log(`Making screenshot for "${projectId}"`);
          takeScreenshot(gl, scene, camera, gl.domElement);
        } else {
          console.log(`Screenshot for "${projectId}" is already taken`);
        }
      }
    }, [loaded, screenshotTaken, isNewProject, modelLoaded, gl, scene, camera]);

    useFrame(() => {
      const targetQuaternion = new THREE.Quaternion();
      camera.getWorldQuaternion(targetQuaternion);

      widgetList.forEach((iteratedWidgetData) => {
        if (
          matricesRefs[iteratedWidgetData.id] &&
          iteratedWidgetData.customization.lookAtMe.enabled
        ) {
          const currentMatrix = matricesRefs[iteratedWidgetData.id];

          const position = new THREE.Vector3();
          const scale = new THREE.Vector3();
          const quaternion = new THREE.Quaternion();
          currentMatrix.decompose(position, quaternion, scale);
          quaternion.slerp(targetQuaternion, 1);

          currentMatrix.compose(position, quaternion, scale);
          matricesRefs[iteratedWidgetData.id] = currentMatrix;
        }
      });
    });

    const handleCameraMovingEnd = async () => {
      if (cameraControlsRef.current) {
        const { camera } = cameraControlsRef.current;
        const target = cameraControlsRef.current.getTarget();
        const state = {
          position: roundToDecimals(camera.position, 7),
          target: roundToDecimals(target, 7),
        };
        await updateProjectState(projectId, state);
      }
      if (selectedWidgetId && !widgetData.customization.lookAtMe.enabled) {
        const newRotation = new THREE.Euler();
        newRotation.setFromRotationMatrix(matricesRefs[selectedWidgetId]);
        setRotation(trimEuler(newRotation));
        handleWidgetLookAtMeChange(trimEuler(newRotation));
      }
    };

    useEffect(() => {
      if (widgetData.id != selectedWidgetId) return;
      if (matricesRefs[selectedWidgetId]) {
        const newRotation = new THREE.Euler();
        newRotation.setFromRotationMatrix(matricesRefs[selectedWidgetId]);
        setRotation(trimEuler(newRotation));
        updateMatrix(position, newRotation, widgetData.id);
        handleWidgetLookAtMeChange(trimEuler(newRotation));
      }
    }, [widgetData.customization.lookAtMe?.enabled]);

    const calculateDropWidgetSpace = (vector) => {
      //On intesection mesh poing
      raycaster.current.setFromCamera(vector, camera);
      const intersects = raycaster.current.intersectObjects(gltf.children, true);
      if (intersects.length > 0) {
        const intersectPoint = intersects[0].point;

        const direction = new THREE.Vector3()
          .subVectors(intersectPoint, camera.position)
          .normalize();
        const position = intersectPoint.add(direction.multiplyScalar(-0.1));
        return position;
      }

      //Just in space
      vector.unproject(camera);
      const dir = vector.sub(camera.position).normalize();
      const pos = camera.position
        .clone()
        .add(dir.multiplyScalar(calculateModelBoundingBoxDimension() * 1.5));
      return trimVector3(pos);
    };

    useImperativeHandle(
      ref,
      () => ({
        zoomIn() {
          if (cameraControlsRef.current) {
            cameraControlsRef.current.dolly(1, true);
          }
        },
        zoomOut() {
          if (cameraControlsRef.current) {
            cameraControlsRef.current.dolly(-1, true);
          }
        },
        fitModelToScale() {
          if (cameraControlsRef.current) {
            cameraControlsRef.current.fitToSphere(boundingSphereRef.current, true);
          }
        },
        getVector3PositionFromDropSpace(dropVector) {
          const dropPosition = calculateDropWidgetSpace(dropVector);
          return dropPosition;
        },
        getMaxModelDimension() {
          return calculateModelBoundingBoxDimension();
        },
      }),
      [cameraControlsRef, gltf]
    );

    const gridConfig = {
      cellSize: 1,
      cellThickness: 0.5,
      cellColor: '#6f6f6f',
      sectionSize: 10,
      sectionThickness: 1,
      sectionColor: cssVarsService.vars.systemFontSelected,
      fadeDistance: 30,
      fadeStrength: 1,
      followCamera: false,
      infiniteGrid: true,
      side: THREE.DoubleSide,
    };

    const takeScreenshot = async (gl, scene, camera, canvas) => {
      if (!canvas || screenshotTaken) return;
      if (cameraControlsRef.current) {
        await cameraControlsRef.current.fitToSphere(boundingSphereRef.current, true);
      }
      setScreenshotTaken(true);
      gl.render(scene, camera);
      const screenshotDataUrl = canvas.toDataURL('image/png');
      const blob = await fetch(screenshotDataUrl).then((res) => res.blob());
      const formData = new FormData();
      const filename = (projectData.name + '.png').replace(/\s+/g, '_');
      formData.append('screenshot', blob, filename);

      /*
      //NOTE: For testing screenshot locally only
      const a = document.createElement('a');
      a.href = screenshotDataUrl;
      a.download = `screenshot.png`;
      a.click();
      */

      await uploadScreenshot('uploadARPreviewScreenshot', projectData.arModel.id, blob, filename);
    };

    //Reset Rotation
    useEffect(() => {
      if (resetTrigger) {
        handleResetSelectedWidget();
        OnResetComplete();
      }
    }, [resetTrigger, OnResetComplete]);

    const handleResetSelectedWidget = () => {
      if (selectedWidgetId == null) return;
      const newRotation = new THREE.Euler(0, 0, 0);
      setRotation(trimEuler(newRotation));
      updateMatrix(position, newRotation, widgetData.id);
      handleWidgetLookAtMeChange(trimEuler(newRotation));
    };

    return (
      <>
        <directionalLight ref={directionalLightRef} intensity={2} position={[5, 10, 7.5]} />
        <ambientLight color="white" intensity={2} />
        <CameraControls
          makeDefault
          ref={cameraControlsRef}
          camera={camera}
          minDistance={0.5}
          maxDistance={100}
          minZoom={0.1}
          maxZoom={1}
          restThreshold={0.01}
          minPolarAngle={0}
          maxPolarAngle={Math.PI}
        />
        <CanvasRaycaster
          onEmptyAreaClick={OnShowWidgetList}
          onWidgetClick={handleLocalWidgetClick}
        />
        <Grid position={[0, 0, 0]} args={[10, 10]} {...gridConfig} />
        <GizmoHelper alignment="bottom-right" renderPriority={10} margin={[100, 100]}>
          <GizmoViewport labelColor="white" axisHeadScale={1} />
        </GizmoHelper>
        <Suspense fallback={null}>
          <EffectComposer multisampling={0}>
            <SMAA />
          </EffectComposer>
        </Suspense>
        <primitive object={gltf} />
        {widgetList.map((iteratedWidgetData) => {
          const isSelected = selectedWidgetId === iteratedWidgetData.id;
          return (
            <React.Fragment key={iteratedWidgetData.id}>
              <PivotControls
                enabled={isSelected}
                onDragEnd={onPivotControlsDragEnd}
                onDrag={(lm, dm, wm, dwm) => handleDrag(lm, dm, wm, dwm, iteratedWidgetData.id)}
                disableRotations={false}
                autoTransform={false}
                matrix={matricesRefs[iteratedWidgetData.id]}
                disableScaling={true}
                lineWidth={3}
                anchor={[0, 0, 0]}
                scale={75}
                fixed
                depthTest={false}>
                <SceneWidgetLabel
                  meshRef={widgetRefs[iteratedWidgetData.id]}
                  widgetData={iteratedWidgetData}
                  isSelected={isSelected}
                  data-widget-id={widgetData.id}
                  handleWidgetLookAtMeChange={handleWidgetLookAtMeChange}
                />
              </PivotControls>
            </React.Fragment>
          );
        })}
      </>
    );
  }
);

export default SceneContent;
