import React, { useState, useEffect } from 'react';
import { usePrevious } from '@core/hooks/usePrevious';
import I18n from '@components/I18n';
import MultiSelect from '@components/MultiSelect';
import { useIsMounted } from '@core/hooks/useIsMounted';
import {
  getIsIncludeInSettings,
  getConfigValueFromWidgetSettings,
} from '@core/canvas/widget.utils';
import { httpService } from '@core/http/HttpService';
import { tagFormatMap, variableFormatMap } from '@core/mapsAndDefinitions';
import styles from '../StepOne.scss';
import { i18nService } from '@core/i18n/I18nService';
import { capitalizeFirstLetterOfEveryWord } from '@core/utils';
import { valueTypeOptions } from '@pages/OrganizationsPage/Variables/Variables.utils';
import classNames from 'classnames';
import { AssetTypeModel } from '../StepOne.interface';
import SingleSelectWithChip from '@components/SingleSelectWithChip';

const VariablesInput = ({
  assetTypes,
  variables,
  onSelectedVariables,
  widgetType,
  isMaxDataTags,
  eventTemplateId = undefined,
  additionalStyles = null,
  isDisabled = false,
  isMulti = true,
  filteredOptionFormats = [],
  setAllVariables = null,
  minified = false,
}) => {
  const isMounted = useIsMounted();
  const previousAssetTypes: AssetTypeModel[] = usePrevious(assetTypes);
  const [variablesOptions, setVariablesOptions] = useState([]);

  function getAssetTypeVariables(assetTypeId) {
    httpService
      .api({
        type: 'getAssetTypeVariables',
        urlParams: { assetTypeId },
        disableBI: true,
      })
      .then((res: any) => {
        if (isMounted.current) {
          setVariablesOptions(res);
        }
        if (setAllVariables) setAllVariables(res);
      });
  }

  function getTableConfig(option) {
    if (minified) {
      return {
        [i18nService.translate('create-widget-page.create-widget.step-one.format')]:
          option.valueType,
        [i18nService.translate('general.name')]: option.name,
      };
    }

    return {
      [i18nService.translate('create-widget-page.create-widget.step-one.format')]: option.valueType,
      [i18nService.translate('general.name')]: option.name,
      [i18nService.translate('general.type')]: i18nService.translate(
        valueTypeOptions.find((opt) => opt.value === option.valueType).label
      ),
    };
  }

  useEffect(() => {
    const currentAssetTypeId = assetTypes.length === 1 ? assetTypes[0].id : null;
    const previousAssetTypeId =
      previousAssetTypes && previousAssetTypes.length === 1 ? previousAssetTypes[0].id : null;

    if (currentAssetTypeId && currentAssetTypeId !== previousAssetTypeId) {
      getAssetTypeVariables(currentAssetTypeId);
    } else if (!currentAssetTypeId) {
      setVariablesOptions([]);
    }
  }, [assetTypes, previousAssetTypes]);

  return (
    <div className={classNames(styles.dataField, additionalStyles?.dataField)}>
      <div className={styles.fieldLabel}>
        <I18n>{capitalizeFirstLetterOfEveryWord(i18nService.translate('variables.caption'))}</I18n>
        {variables?.length > 0 && ` (${variables?.length})`}
        <div className={classNames(styles.fieldSubLabel, additionalStyles?.fieldSubLabel)}>
          <I18n>
            {!eventTemplateId
              ? 'create-widget-page.create-widget.step-one.variables-sub'
              : 'create-event-data-source-page.create-event-data-source.step-one.variables-sub'}
          </I18n>
        </div>
      </div>
      <div className={classNames(styles.inputField, additionalStyles?.inputField)}>
        {!isMulti ? (
          <SingleSelectWithChip
            id="21e9ef6e-06db-4cb6-8001-5c41b861cd52"
            values={variables}
            isOptionDisabled={(option) => {
              return (
                filteredOptionFormats.length > 0 &&
                !filteredOptionFormats.includes(variableFormatMap[option.valueType])
              );
            }}
            isDisabled={assetTypes.length !== 1 || isDisabled}
            options={variablesOptions}
            getOptionLabel={(option) => option.name}
            getOptionValue={(option) => option.id}
            onChange={onSelectedVariables}
            tableConfig={(option) => getTableConfig(option)}
            optionType="variableDataOptions"
          />
        ) : (
          <MultiSelect
            id="21e9ef6e-06db-4cb6-8001-5c41b861cd52"
            isOptionDisabled={(option) =>
              (tagFormatMap[option.format] &&
                getConfigValueFromWidgetSettings(widgetType, 'assetIsNotDisabled') &&
                !getIsIncludeInSettings(
                  widgetType,
                  'assetIsNotDisabled',
                  tagFormatMap[option.format]
                )) ||
              (isMaxDataTags && !variables.find((variable) => option.id === variable.id)) ||
              (getConfigValueFromWidgetSettings(widgetType, 'acceptOnlyWritableTags') &&
                option.access === 'RO')
            }
            closeMenuOnSelect={widgetType === 'map'}
            isDisabled={assetTypes.length !== 1 || isDisabled}
            values={variables}
            options={variablesOptions}
            getOptionLabel={(option) => option.name}
            getOptionValue={(option) => option.id}
            onChange={onSelectedVariables}
            tableConfig={(option) => getTableConfig(option)}
            optionType="variableDataOptions"
          />
        )}
      </div>
    </div>
  );
};

export default VariablesInput;
