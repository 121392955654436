import { conditionsService } from '@core/ConditionsService';

export const MIN_SIZE = 0.1;
export const MAX_SIZE = 10;
export const MIN_POSITION = -100;
export const MAX_POSITION = 100;

export function getCustomizationValidationByWidget(widgetData): (widgetData) => any {
  switch (widgetData.type) {
    case 'image_by_value':
      return (widgetData) => {
        const isBoolean =
          widgetData.metrics?.length &&
          widgetData.metrics[0].type === 'booleanType' &&
          widgetData.scope === 'LAST_VALUE';
        if (isBoolean) {
          return !!(
            widgetData.customization?.conditions?.length &&
            !widgetData.customization?.conditions?.some((condition) => !condition.imageId)
          );
        } else {
          const errors = getErrors(widgetData.customization, widgetData.customization.conditions);

          return !!(
            (!Object.keys(errors)?.length || !Object.values(errors)?.some((err) => err)) &&
            widgetData.customization?.conditions?.length &&
            !widgetData.customization?.conditions?.some(
              (condition) =>
                condition.fromValue == null || condition.toValue == null || !condition.imageId
            )
          );
        }
      };
    case 'value':
      return (widgetData) => true;
    default:
      return (widgetData) => true;
  }
}

export function getErrors(customization, conditions) {
  let errors = customization.errors ? { ...customization.errors } : {};
  conditions.forEach((c, idx) => {
    const isValid = conditionsService.validate(conditions, c);

    if (!isValid && !Object.keys(errors).some((key) => key === c.id)) {
      errors[c.id] = true;
    } else {
      errors[c.id] = false;
    }
  });

  return errors;
}

export function getIsConfirmationRequired(widgetType, widgetData) {
  switch (widgetType) {
    case 'image_by_value':
      return !!(widgetData?.customization?.conditions
        ? widgetData?.customization?.conditions.some(
            (c) => c.fromValue || c.fromValue === 0 || c.toValue || c.toValue === 0 || c.imageId
          )
        : false);
    default:
      return false;
  }
}
