import React, { useMemo, useState, useEffect } from 'react';
import { useThree, useFrame, useLoader } from '@react-three/fiber';
import * as THREE from 'three';
import { Text } from '@react-three/drei';
import { cssVarsService } from '@core/CssVarsService';
import { vertexShader, fragmentShader } from './Shaders/RoundedBoxShader';
import { vertexTShader, fragmentTShader } from './Shaders/WidgetTextShader';
import { initialCustomizationFormData } from './InitialProperties';
import { usePrevious } from '@core/hooks/usePrevious';

function loadSvgAsHighResTexture(svgUrl, width, height) {
  return new Promise((resolve) => {
    const canvas = document.createElement('canvas');
    const context = canvas.getContext('2d');

    canvas.width = width;
    canvas.height = height;

    const img = new Image();
    img.onload = () => {
      context.drawImage(img, 0, 0, width, height);
      const texture = new THREE.Texture(canvas);
      texture.needsUpdate = true;
      resolve(texture);
    };
    img.src = svgUrl;
  });
}

function SceneWidgetLabel(props) {
  const { meshRef, widgetData, isSelected, handleWidgetLookAtMeChange } = props;
  const { camera } = useThree();
  const { statesForm } = widgetData;
  const prevDimensions: any = usePrevious(widgetData?.statesForm);
  const w = statesForm.w || prevDimensions.w;
  const h = statesForm.h || prevDimensions.h;
  const borderRadius = Math.min(w, h) * 0.12;
  const borderWidth = Math.min(w, h) * 0.015;
  const [imageAspect, setImageAspect] = useState(1);
  const [imageTexture, setImageTexture] = useState(null);
  const isImageByValueType = widgetData.type === 'image_by_value';

  const widgetBackgroundMaterial = useMemo(() => {
    return new THREE.ShaderMaterial({
      vertexShader: vertexShader,
      fragmentShader: fragmentShader,
      uniforms: {
        mainColor: {
          value: new THREE.Color(
            widgetData.customization.backgroundColor
              ? widgetData.customization.backgroundColor.slice(0, 7)
              : '#488fa7'
          ),
        },
        borderColor: { value: new THREE.Color(cssVarsService.vars.systemFont) },
        borderRadius: { value: borderRadius },
        borderWidth: { value: borderWidth },
        size: { value: new THREE.Vector2(w, h) },
        mainAlpha: {
          value:
            widgetData.customization.transparentBackground == null
              ? 0.5
              : widgetData.customization.transparentBackground === true
              ? 0
              : parseInt(
                  widgetData.customization.backgroundColor
                    ? widgetData.customization.backgroundColor.slice(7, 9)
                    : initialCustomizationFormData.backgroundColor.slice(7, 9),
                  16
                ) / 255,
        },
        borderAlpha: { value: isSelected ? 1 : 0 },
      },
      transparent: true,
      depthWrite: false,
      depthTest: true,
      blending: THREE.NormalBlending,
      side: THREE.DoubleSide,
    });
  }, [isSelected, widgetData.customization, widgetData.statesForm]);

  const widgetTextMaterial = useMemo(() => {
    return new THREE.ShaderMaterial({
      vertexShader: vertexTShader,
      fragmentShader: fragmentTShader,
      uniforms: {
        mainColor: {
          value: new THREE.Color(
            widgetData.customization.valueColor
              ? widgetData.customization.valueColor.slice(0, 7)
              : widgetData.customization.titleColor
              ? widgetData.customization.titleColor.slice(0, 7)
              : initialCustomizationFormData.valueColor.slice(0, 7)
          ),
        },
        mainAlpha: {
          value:
            parseInt(
              widgetData.customization.valueColor
                ? widgetData.customization.valueColor.slice(7, 9)
                : initialCustomizationFormData.valueColor.slice(7, 9),
              16
            ) / 255,
        },
      },
      transparent: true,
      depthWrite: false,
      depthTest: true,
      blending: THREE.NormalBlending,
      side: THREE.DoubleSide,
    });
  }, [isSelected, widgetData.customization, widgetData.statesForm]);

  useEffect(() => {
    if (isImageByValueType) {
      const loader = new THREE.ImageLoader();
      loader.load('/assets/images/empty_Image.svg', (image) => {
        const aspect = image.width / image.height;
        setImageAspect(aspect);
      });
    }
  }, [widgetData.type]);

  useEffect(() => {
    if (isImageByValueType) {
      const loader = new THREE.ImageLoader();
      loader.load('/assets/images/empty_Image.svg', (image) => {
        const aspect = image.width / image.height;
        setImageAspect(aspect);
      });

      loadSvgAsHighResTexture('/assets/images/empty_Image.svg', 512, 512).then((texture) => {
        setImageTexture(texture);
      });
    } else {
      setImageTexture(null);
    }
  }, [widgetData.type]);

  const imageWidth = Math.min(w, h) * 0.9;
  const imageHeight = (Math.min(w, h) / imageAspect) * 0.9;

  const text =
    (!widgetData.hideWidgetName ? widgetData.name : '') +
    '\n' +
    (isImageByValueType ? '' : 'Value');

  const fontSize = Math.min(w, h) * (isImageByValueType ? 0.1 : 0.2);

  return (
    <mesh ref={meshRef} userData={{ widgetData: widgetData }} renderOrder={0}>
      {/* Background */}
      <planeGeometry attach="geometry" args={[w, h]} />
      <primitive attach="material" object={widgetBackgroundMaterial} />

      {/* Image Texture */}
      {imageTexture && (
        <mesh position={[0, 0, 0]} renderOrder={1}>
          <planeGeometry attach="geometry" args={[imageWidth, imageHeight]} />
          <meshBasicMaterial
            attach="material"
            map={imageTexture}
            transparent
            side={THREE.DoubleSide}
          />
        </mesh>
      )}

      {/* Text */}
      <Text
        position={[0, isImageByValueType ? statesForm.h / 2 - fontSize / 2 : 0, 0]}
        fontSize={fontSize}
        maxWidth={300}
        lineHeight={1}
        letterSpacing={0.02}
        anchorY={isImageByValueType ? 'top' : 'middle'}
        textAlign="center"
        renderOrder={1}>
        <primitive attach="material" object={widgetTextMaterial} />
        {text}
      </Text>
    </mesh>
  );
}

export default SceneWidgetLabel;
