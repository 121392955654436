import { initialWidgetData } from '../CreateWidgetPage/widgets.utils';

export const initialCustomizationFormData = {
  valueColor: '#FFFFFFFF',
  backgroundColor: '#488fa7a1',
  transparentBackground: false,
  decimalDigits: 2,
  symbol: '',
  showSymbolLeft: false,
  description: null,
  valueFontSize: 35,
  symbolFontSize: 20,
  descriptionFontSize: 12,
  dateTimeFormat: null,
  symbolColor: '#3b406e',
  remarkColor: '#3b406e',
  lookAtMe: {
    x: 0,
    y: 0,
    z: 0,
    enabled: true,
  },
};

export const initialCustomizationImageByValueFormData = {
  titleColor: '#FFFFFF',
  conditions: [],
  lookAtMe: {
    x: 0,
    y: 0,
    z: 0,
    enabled: true,
  },
};

export const initialStatesFormData = {
  w: 1,
  h: 1,
  x: 0,
  y: 0,
  z: 0,

  //Hardcoded values:
  layoutStateType: 'DESKTOP',
};

export const initialMetricsItem = {
  order: 0,
  valueType: '', // WidgetTagType - TAG / ASSET_PROPERTY / VARIABLE / CALCULATION
  valueId: 0,
  operation: null,
  type: '',
};

export const combinedArWidgetInitialValueData = {
  ...initialWidgetData,
  id: 0,
  type: 'value',
  hideWidgetName: false,
  creationStage: 'FIRST_STAGE', // "Acceptable values are ['FIFTH_STAGE', 'FIRST_STAGE', 'FOURTH_STAGE', 'SECOND_STAGE', 'THIRD_STAGE']."
  scope: 'LAST_VALUE',
  status: 'DRAFT',
  customization: initialCustomizationFormData,
  statesForm: initialStatesFormData,
  metrics: [],
  assetProperties: [],
  tags: [],
  variables: [],
  localTags: [],
  eventTemplateId: null,
};

export const combinedArWidgetInitialImageByValueData = {
  ...initialWidgetData,
  id: 0,
  type: 'image_by_value',
  hideWidgetName: true,
  creationStage: 'FIRST_STAGE', // "Acceptable values are ['FIFTH_STAGE', 'FIRST_STAGE', 'FOURTH_STAGE', 'SECOND_STAGE', 'THIRD_STAGE']."
  scope: 'LAST_VALUE',
  status: 'DRAFT',
  customization: initialCustomizationImageByValueFormData,
  statesForm: initialStatesFormData,
  metrics: [],
  assetProperties: [],
  tags: [],
  variables: [],
  eventTemplateId: null,
};
