import React, { useEffect, useMemo, useState } from 'react';
import styles from './CalculatedData.scss';
import I18n from '@components/I18n';
import {
  editExpression,
  getDataSources,
  getExpressionToDisplay,
} from '@core/expressionUtilities/expressionUtilities';
import classNames from 'classnames';
import Icon from '@components/Icon';
import { i18nService } from '@core/i18n/I18nService';
import { modalService } from '@core/modals/ModalService';
import { dynamicSort } from '@core/utils';
import { useCalculations } from '@pages/CreateWidgetPage/DataStepComponent/StepThree/GroupingAndMeasures/Metrics/Calculations/useCalculations';
import DeleteIcon from '@material-ui/icons/Delete';
import { getDataSourcesWithDataType } from '@pages/CreateWidgetPage/DataStepComponent/StepThree/GroupingAndMeasures/Metrics/Calculations/Calculations.utils';
import { singleServerCalculationToLocal } from '@pages/CreateWidgetPage/widgets.utils';
import { getIsConfirmationRequired } from '../SidePanelToolbar.utils';

const cleanedWidgetData = { calculations: [], localTags: [], tags: [], variables: [], metrics: [] };

function CalculatedData(props) {
  const {
    widgetData,
    setWidgetData,
    allNumericTags,
    allNumericVariables,
    isDisabled,
    updateData,
    handlePromptUpdate,
  } = props;
  const { type, calculations } = widgetData;
  const [editedCalculation, setEditedCalculation] = useState(null);
  const { onCalculationChanged } = useCalculations({ widgetData, setWidgetData });
  const deleteButtonDisabled =
    !editedCalculation || !editedCalculation?.expression || editedCalculation?.expression === '';

  useEffect(() => {
    if (calculations?.length) {
      if (JSON.stringify(calculations[0]) !== JSON.stringify(editedCalculation)) {
        setEditedCalculation(singleServerCalculationToLocal(calculations[0], widgetData));
      }
    } else {
      setEditedCalculation(null);
    }
  }, [calculations]);

  const expressionToDisplay = useMemo(() => {
    return editedCalculation ? getExpressionToDisplay(editedCalculation.expression, type) : null;
  }, [editedCalculation]);

  function deleteLocalTag() {
    updateData({ ...widgetData, ...cleanedWidgetData });
    setEditedCalculation(null);
  }

  function promptDeleteCalculation() {
    modalService
      .openModal('confirm', {
        headerText: 'augmented-reailty.widget.side-panel.remove-calculation-popup.header',
        text: 'augmented-reailty.widget.side-panel.remove-calculation-popup.text',
        iconType: 'attention_image',
        confirmText: 'general.confirm',
        cancelText: 'general.cancel',
        showCloseBtn: true,
      })
      .then(async (confirm) => {
        if (confirm) {
          deleteLocalTag();
        }
      });
  }

  function onDeleteClicked() {
    if (['image_by_value'].includes(type) && getIsConfirmationRequired(type, widgetData)) {
      handlePromptUpdate(
        { ...widgetData, ...cleanedWidgetData },
        deleteLocalTag,
        getIsConfirmationRequired(type, widgetData)
      );
    } else {
      promptDeleteCalculation();
    }
  }

  const handleChange = async (key: string, value) => {
    const defaultCalculation = {
      valueType: 'CALCULATION',
      valueId: 1,
      expression: '0',
      operation: null,
      order: 0,
      metrics: [],
    };

    let newCalculation = { ...defaultCalculation, [key]: value };

    if (key === 'expression') {
      if (value !== '') {
        const id = 1;
        const order = 0;
        const localTag = { id: id, name: 'Local tag', format: 'FLOAT32' };
        const newMetric = {
          valueId: id,
          valueType: 'CALCULATION',
          order: order,
          operation: null,
        };

        const newWidgetData = {
          ...widgetData,
          calculations: [newCalculation],
          localTags: [localTag],
          tags: allNumericTags,
          variables: allNumericVariables,
          metrics: [newMetric],
        };

        setEditedCalculation(newCalculation);
        onCalculationChanged(newCalculation);
        updateData(newWidgetData);
      } else {
        if (editedCalculation?.expression && editedCalculation?.expression !== '') {
          if (type === 'image_by_value') {
            handlePromptUpdate(
              { ...widgetData, ...cleanedWidgetData },
              updateData,
              getIsConfirmationRequired(type, widgetData)
            );
          } else {
            promptDeleteCalculation();
          }
        }
      }
    }
  };

  return (
    <div>
      <I18n>augmented-reailty.widget.side-panel.calculated-data-header</I18n>
      <div className={classNames(styles.container, isDisabled && styles.disabled)}>
        <div className={styles.expressionWrapper}>
          {expressionToDisplay && (
            <div
              title={expressionToDisplay}
              className={classNames(styles.expression, 'ellipsis-overflow')}>
              {expressionToDisplay}
            </div>
          )}
        </div>
        <div className={styles.buttonsWrapper}>
          <div title={i18nService.translate('general.delete')}>
            <DeleteIcon
              className={deleteButtonDisabled ? 'disabled' : 'pointer'}
              htmlColor={'var(--systemFont)'}
              fontSize="small"
              onClick={deleteButtonDisabled ? null : onDeleteClicked}
            />
          </div>
          <Icon
            type="calculation"
            color="var(--systemFont)"
            tooltipText={i18nService.translate('expression-builder.calculation.caption')}
            width={'24px'}
            height={'24px'}
            style={{ marginBottom: '1px' }}
            onClick={() => {
              editExpression(
                editedCalculation?.expression,
                [
                  ...getDataSources(
                    widgetData,
                    getDataSourcesWithDataType(allNumericVariables, 'systemProperties'),
                    'VARIABLES'
                  ).sort(dynamicSort('name', '+')),
                  ...getDataSources(
                    widgetData,
                    getDataSourcesWithDataType(allNumericTags, 'assetTags')
                  ).sort(dynamicSort('name', '+')),
                ],
                handleChange,
                true
              );
            }}
            disabled={isDisabled}
          />
        </div>
      </div>
    </div>
  );
}

export default CalculatedData;
