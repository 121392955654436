export const onInputFocus = (getLastSearches) => {
  getLastSearches && getLastSearches();
};

// In case of click on the search icon
export const onSearchClicked = (
  searchText,
  searchFunc,
  setSearchResultsText,
  minCharsToSearch,
  searchWithoutMinLength
) => {
  search(searchText, searchFunc, setSearchResultsText, minCharsToSearch, searchWithoutMinLength);
};

// In case of choosing an option from the dropdown
export const onValueChanged = (e, setSearchText) => {
  const text = e.target.textContent;
  !!text && setSearchText(text);
};

export const handleKeyUp = (
  e,
  setSearchText,
  searchFunc,
  searchWithoutMinLength,
  setSearchResultsText,
  minCharsToSearch,
  triggerFunctionWhenTyping
) => {
  const text = e.target.value;
  // In case of click on Enter or Backspace
  if (e.key === 'Enter' || !text.length || triggerFunctionWhenTyping)
    search(text, searchFunc, setSearchResultsText, minCharsToSearch, searchWithoutMinLength);
  // In case of typing text
  if (e.key !== 'Enter') {
    setSearchText(text);
  }
};

const search = (
  text,
  searchFunc,
  setSearchResultsText,
  minCharsToSearch,
  searchWithoutMinLength
) => {
  if (text.length >= minCharsToSearch || !text.length || searchWithoutMinLength) {
    searchFunc(text);
    setSearchResultsText(text);
  }
};

// In case of click on the clear icon
export const resetSearch = (setSearchText, searchFunc, setSearchResultsText) => {
  setSearchText('');
  searchFunc(null);
  setSearchResultsText('');
};
