import React, { useState, useEffect } from 'react';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import classNames from 'classnames';
import Icon from '@components/Icon';
import I18n from '@components/I18n';
import { SearchInputProps } from './searchInput.interface';
import styles from './SearchInput.scss';
import { i18nService } from '@core/i18n/I18nService';
import { useSelector } from '@src/redux/useSelector';
import {
  resetSearch,
  handleKeyUp,
  onValueChanged,
  onSearchClicked,
  onInputFocus,
} from './SearchInput.utils';

const searchingMessage = 'Searching...';
const recalculateIsNeededMessage = 'Click on the search button to recalculate';

function SearchInput(props: SearchInputProps) {
  const [searchText, setSearchText] = useState('');
  const [searchResultsText, setSearchResultsText] = useState('');
  const languageId = useSelector((state) => state.config.languageId);
  const {
    pageName,
    totalCount,
    resultsCount,
    getLastSearches,
    searchFunc,
    searchWithoutMinLength = false,
    clearSearch,
    lastSearches,
    minCharsToSearch = 2,
    triggerFunctionWhenTyping = false,
    notEnoughCharText,
    onClearSearch,
    shouldRecalculate,
    isSearching,
    backgroundColor = 'var(--systemContentBackground)',
  } = props;

  useEffect(() => {
    clearSearch && resetSearch(setSearchText, searchFunc, setSearchResultsText);
  }, [clearSearch]);

  return (
    <div className={styles.searchInputContainer}>
      <div className={styles.inputWrapper}>
        <Autocomplete
          id={styles.searchInput}
          freeSolo
          options={lastSearches || []}
          onKeyUp={(e) => {
            handleKeyUp(
              e,
              setSearchText,
              searchFunc,
              searchWithoutMinLength,
              setSearchResultsText,
              minCharsToSearch,
              triggerFunctionWhenTyping
            );
          }}
          onFocus={() => onInputFocus(getLastSearches)}
          onChange={(e) => onValueChanged(e, setSearchText)}
          value={searchText}
          renderInput={(params) => (
            <TextField
              {...params}
              variant="standard"
              fullWidth
              style={{ borderRadius: 25, backgroundColor: backgroundColor }}
              InputProps={{
                disableUnderline: true,
                placeholder: i18nService.translate('general.search', languageId),
              }}
            />
          )}
        />
        {searchText && (
          <Icon
            type="remove"
            className={classNames(styles.clearIcon, 'pointer')}
            onClick={() => {
              resetSearch(setSearchText, searchFunc, setSearchResultsText);
              if (onClearSearch) {
                onClearSearch();
              }
            }}
            color="var(--systemFont)"
          />
        )}
        <Icon
          type="search"
          className={classNames(styles.searchIcon, 'pointer')}
          onClick={() =>
            onSearchClicked(
              searchText,
              searchFunc,
              setSearchResultsText,
              minCharsToSearch,
              searchWithoutMinLength
            )
          }
          color="var(--systemFont)"
        />
      </div>

      {notEnoughCharText && searchText.length > 0 ? (
        shouldRecalculate ? (
          <span className={styles.message}>{recalculateIsNeededMessage}</span>
        ) : isSearching && searchText.length >= minCharsToSearch ? (
          <span className={styles.message}>{searchingMessage}</span>
        ) : searchText.length < minCharsToSearch ? (
          <span className={styles.message}>{notEnoughCharText}</span>
        ) : (
          <I18n
            className={styles.message}
            resultsCount={resultsCount}
            totalCount={totalCount}
            pageName={pageName}>
            {resultsCount && totalCount
              ? resultsCount === 1
                ? 'search-input-msg.found-one'
                : 'search-input-msg.found'
              : 'search-input-msg.not-found'}
          </I18n>
        )
      ) : (
        searchResultsText.length > 0 && (
          <I18n
            className={styles.message}
            resultsCount={resultsCount}
            totalCount={totalCount}
            pageName={pageName}>
            {resultsCount && totalCount
              ? resultsCount === 1
                ? 'search-input-msg.found-one'
                : 'search-input-msg.found'
              : 'search-input-msg.not-found'}
          </I18n>
        )
      )}
    </div>
  );
}
export default SearchInput;
