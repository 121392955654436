import React, { useState, useEffect, useMemo, useRef } from 'react';
import SortableTree, { changeNodeAtPath } from 'react-sortable-tree';
import 'react-sortable-tree/style.css';
import NodeContent from './NodeContent/NodeContent';
import classNames from 'classnames';
import SelectedHelpers from './SelectedHelpers';
import styles from './OrganizationsTree.scss';
import Icon from '@components/Icon';
import { makeStyles, Accordion, AccordionSummary, AccordionDetails } from '@material-ui/core';
import MuiExpandMoreIcon from '@components/MuiExpandMoreIcon';

import { useSelector } from 'react-redux';
import {
  getNodeKey,
  findNodeAndParentById,
  updateTreeChanges,
  onNodeClicked,
  updateMBMap,
  onAddingOrg,
  deleteSelected,
  setFirstNode,
  deleteOrganization,
  buildTree,
  expandLeafNodeIfPossible,
} from './organizationsTree.utils';
import { useHistory } from 'react-router-dom';
import TreeLegend from './TreeLegend';
import I18n from '@components/I18n';
import SearchInput from '@pages/DeviceManagment/Assets/SearchInput';

interface SelectedType {
  childCount?: number;
}

const TIMEOUT_BEFORE_SEARCH = 400;
const MAX_SEARCH_ID_UNTIL_RESTART_IDS = 100000000;
const UNITRONICS_MAIN_ID = '1';
const SEARCH_BAR_BACKGROUND_STYLE = 'var(--systemScreenBackground)';

const useStyles = makeStyles((theme: any) => ({
  tree: {
    '& .rst__lineChildren::after': {
      backgroundColor: 'var(--systemFont)',
      height: 5,
    },
    '& .rst__lineHalfHorizontalRight::before': {
      backgroundColor: 'var(--systemFont)',
    },
    '& .rst__lineFullVertical::after': {
      backgroundColor: 'var(--systemFont)',
    },
    '& .rst__lineHalfVerticalTop::after': {
      backgroundColor: 'var(--systemFont)',
    },
    '& .rst__lineHalfVerticalBottom::after': {
      backgroundColor: 'var(--systemFont)',
    },
    transitionDuration: '0.35s',
  },
  legendPanel: {
    '&.MuiAccordion::before': {
      display: 'none',
    },
  },
  legend: {
    marginBottom: 10,
    boxShadow: theme.app.boxShadowGrey,
    backgroundColor: 'var(--systemScreenBackground)',
    '&.MuiSvgIcon-root': {
      transform: 'rotate(180deg)',
    },
  },
  legendCaption: {
    height: '50px',
  },
  label: {
    fontSize: 14,
    fontWeight: 900,
    color: 'var(--systemFont)',
  },
}));

function OrganizationsTree(props) {
  const history = useHistory();
  const organizationDetails = useSelector((state: any) => state.config.organizationDetails);
  const selectedOrganizationDetails = useSelector((state: any) => state.organizations);
  const userOrganizationId = useSelector((state: any) => state.login.userOrganizationId);
  const [treeData, setTreeData] = useState([]);
  const [amountOfResultsFoundInSearch, setAmountOfResultsFoundInSearch] = useState(0);
  const [searchResultTreeData, setSearchResultTreeData] = useState(null);
  const [selected, setSelected] = useState<SelectedType>({});
  const [openTree, setOpenTree] = useState(true);
  const [totalCount, setTotalCount] = useState<number>(organizationDetails.childCount);
  const [isSearching, setIsSearching] = useState(false);
  const [shouldRecalculate, setShouldRecalculate] = useState(false);
  const machinBuilderMap = useMemo(() => ({}), []);
  const classes = useStyles(props);

  const [legendExpanded, setLegendExpanded] = useState(true);
  const searchTimeoutRef = useRef<NodeJS.Timeout>();
  const searchIdRef = useRef<number>(0);

  useEffect(() => {
    const count =
      selected?.childCount !== undefined
        ? selected.childCount + 1
        : organizationDetails.childCount + 1;
    setTotalCount(count);
  }, [selected]);

  useEffect(() => {
    setFirstNode(
      organizationDetails,
      setTreeData,
      setSelected,
      machinBuilderMap,
      userOrganizationId,
      history
    );
  }, [organizationDetails]);

  useEffect(() => {
    if (selectedOrganizationDetails.status === 'DELETE') {
      deleteOrganization(
        treeData,
        setTreeData,
        selected,
        setSelected,
        organizationDetails,
        machinBuilderMap,
        userOrganizationId,
        history
      );
    } else {
      //update tree according to changes that happen in org details.
      searchResultTreeData !== null
        ? updateTreeChanges(
            searchResultTreeData,
            setSearchResultTreeData,
            selected,
            setSelected,
            selectedOrganizationDetails,
            organizationDetails
          )
        : updateTreeChanges(
            treeData,
            setTreeData,
            selected,
            setSelected,
            selectedOrganizationDetails,
            organizationDetails
          );
    }
  }, [selectedOrganizationDetails]);

  const expandSelected = () => {
    collapseSelected(true);
  };

  const onUpdatingSearchTree = (updatedTreeData) => {
    setSearchResultTreeData(updatedTreeData);
  };

  const loadTreeData = async (searchText) => {
    if (searchIdRef.current > MAX_SEARCH_ID_UNTIL_RESTART_IDS) {
      searchIdRef.current = 0;
    }

    if (searchTimeoutRef.current) {
      clearTimeout(searchTimeoutRef.current);
    }

    const searchId = searchIdRef.current + 1;
    searchIdRef.current = searchId;

    setShouldRecalculate(false);
    setIsSearching(true);
    const hasSearchCancelled = () => searchId !== searchIdRef.current;

    searchTimeoutRef.current = setTimeout(async () => {
      try {
        if (searchText.len < 3) {
          setSearchResultTreeData(null);
          setAmountOfResultsFoundInSearch(0);
          return;
        }
        const data = await buildTree(searchText, hasSearchCancelled);
        if (!hasSearchCancelled()) {
          if (data && data.amountOfResultsFound > 0) {
            setSearchResultTreeData(data.treeData);
            setAmountOfResultsFoundInSearch(data.amountOfResultsFound);
          } else {
            setSearchResultTreeData(null);
            setAmountOfResultsFoundInSearch(0);
          }
          setIsSearching(false);
        }
      } catch (error) {
        console.error('Organization tree search error:', error);
        if (!hasSearchCancelled()) {
          setIsSearching(false);
        }
      }
    }, TIMEOUT_BEFORE_SEARCH);
  };

  useEffect(() => {
    return () => {
      if (searchTimeoutRef.current) {
        clearTimeout(searchTimeoutRef.current);
      }
    };
  }, []);

  const collapseSelected = (expanded) => {
    const { current } = findNodeAndParentById(treeData, selected);

    const data = changeNodeAtPath({
      treeData,
      path: current.path,
      newNode: {
        ...current.node,
        expanded: expanded === true,
      },
      getNodeKey,
    });
    setTreeData(data);
  };

  const handleLegendChanged =
    (panel: string) => (event: React.SyntheticEvent, isExpanded: boolean) => {
      setLegendExpanded(isExpanded ? !!panel : false);
    };

  return (
    <div
      className={classNames(
        styles.wrapper,
        styles.enableAnimation,
        openTree ? styles.expanded : styles.narrow
      )}
      style={{ marginRight: openTree ? 0 : 37 }}>
      <Icon
        color="var(--systemScreenBackground)"
        onClick={() => setOpenTree(!openTree)}
        style={{
          position: 'absolute',
          right: -5,
          top: '50%',
          cursor: 'pointer',
          transform: 'translateY(-32px)',
        }}
        type={openTree ? 'organizationSideMenuOpen' : 'organizationSideMenuClose'}
      />
      <div
        className={classNames(
          styles.treeWrapper,
          styles.enableAnimationTree,
          !openTree ? styles.collapsedTree : {}
        )}>
        {selected && (
          <>
            <div className={classNames({ [styles.disabled]: searchResultTreeData !== null })}>
              <SelectedHelpers
                expandSelected={expandSelected}
                collapseSelected={collapseSelected}
                deleteSelected={() => deleteSelected(selected, treeData, setTreeData, setSelected)}
              />
            </div>
            {userOrganizationId === UNITRONICS_MAIN_ID && (
              <div className={styles.searchBarContainer}>
                <SearchInput
                  resultsCount={amountOfResultsFoundInSearch}
                  totalCount={totalCount}
                  searchFunc={loadTreeData}
                  searchWithoutMinLength={true}
                  pageName="Search Results"
                  minCharsToSearch={3}
                  triggerFunctionWhenTyping={true}
                  notEnoughCharText={'Type at least 3 characters..'}
                  onClearSearch={() => {
                    setSearchResultTreeData(null);
                    setAmountOfResultsFoundInSearch(0);
                  }}
                  isSearching={isSearching}
                  shouldRecalculate={shouldRecalculate}
                  backgroundColor={SEARCH_BAR_BACKGROUND_STYLE}
                />
              </div>
            )}
          </>
        )}
        {searchResultTreeData !== null ? (
          <SortableTree
            isVirtualized={false}
            rowHeight={40}
            canDrag={false}
            treeData={searchResultTreeData}
            getNodeKey={getNodeKey}
            className={classes.tree}
            style={{
              overflow: openTree ? 'auto' : 'hidden',
              height:
                userOrganizationId === UNITRONICS_MAIN_ID
                  ? legendExpanded
                    ? 'calc(100% - 385px)'
                    : 'calc(100% - 185px)'
                  : legendExpanded
                  ? 'calc(100% - 310px)'
                  : 'calc(100% - 110px)',
            }}
            innerStyle={{ padding: '20px 0px' }}
            generateNodeProps={(rowData) => ({
              onNodeClicked: () => {
                onNodeClicked(
                  rowData,
                  organizationDetails,
                  machinBuilderMap,
                  userOrganizationId,
                  history,
                  setSelected
                );
                setShouldRecalculate(true);
              },
              selected: selected && selected['id'] === rowData.node.id,
              cannotExpand:
                rowData.node.type === 'MACHINE_BUILDER' &&
                organizationDetails.type === 'UNITRONICS_CHANNEL',
              onAddClicked: () =>
                onAddingOrg(
                  rowData,
                  selectedOrganizationDetails,
                  setSearchResultTreeData,
                  searchResultTreeData,
                  selected
                ),
              updateMBMap: () => {
                expandLeafNodeIfPossible(rowData.node, searchResultTreeData, onUpdatingSearchTree),
                  updateMBMap(rowData.node, machinBuilderMap);
              },
            })}
            onChange={(newTreeData) => {
              setSearchResultTreeData(newTreeData);
            }}
            nodeContentRenderer={NodeContent}
          />
        ) : (
          treeData.length &&
          treeData[0].children && (
            <SortableTree
              isVirtualized={false}
              rowHeight={40}
              canDrag={false}
              treeData={treeData}
              getNodeKey={getNodeKey}
              className={classes.tree}
              style={{
                overflow: openTree ? 'auto' : 'hidden',
                height:
                  userOrganizationId === UNITRONICS_MAIN_ID
                    ? legendExpanded
                      ? 'calc(100% - 385px)'
                      : 'calc(100% - 185px)'
                    : legendExpanded
                    ? 'calc(100% - 310px)'
                    : 'calc(100% - 110px)',
              }}
              innerStyle={{ padding: '20px 0px' }}
              generateNodeProps={(rowData) => ({
                onNodeClicked: () =>
                  onNodeClicked(
                    rowData,
                    organizationDetails,
                    machinBuilderMap,
                    userOrganizationId,
                    history,
                    setSelected
                  ),
                selected: selected && selected['id'] === rowData.node.id,
                cannotExpand:
                  rowData.node.type === 'MACHINE_BUILDER' &&
                  organizationDetails.type === 'UNITRONICS_CHANNEL',
                onAddClicked: () =>
                  onAddingOrg(
                    rowData,
                    selectedOrganizationDetails,
                    setTreeData,
                    treeData,
                    selected
                  ),
                updateMBMap: () => {
                  updateMBMap(rowData.node, machinBuilderMap);
                },
              })}
              // onVisibilityToggle={n => getChildren(n, setTreeData)}
              onChange={(treeData, props) => {
                setTreeData(treeData);
              }}
              nodeContentRenderer={NodeContent}
            />
          )
        )}
        {openTree && (
          <div className={styles.legendPanel}>
            <Accordion
              square={true}
              className={classes.legend}
              onChange={handleLegendChanged('legend')}
              expanded={legendExpanded}>
              <AccordionSummary
                className={classes.legendCaption}
                expandIcon={<MuiExpandMoreIcon />}>
                <I18n className={classes.label}>{'organizations-page.legend'}</I18n>
              </AccordionSummary>
              <AccordionDetails>
                <TreeLegend />
              </AccordionDetails>
            </Accordion>
          </div>
        )}
      </div>
    </div>
  );
}

export default OrganizationsTree;
