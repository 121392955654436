import { concat, compact, cloneDeep } from 'lodash';
import { tagFormatMap, variableFormatMap } from '@core/mapsAndDefinitions';
export const tableCfg = [
  {
    style: { paddingRight: '10px', minWidth: '35%', width: '35%' },
    headerLabel: 'create-widget-page.create-widget.step-three.multiply-tag',
  },
  {
    style: { paddingRight: '20px', minWidth: '65%', width: '65%' },
    headerLabel: 'create-widget-page.create-widget.step-three.expression',
  },
];

export const changeWidgetCalculations = (updatedRow, index, setWidgetData) => {
  setWidgetData((prevState) => {
    //when coulmns includes multi types set dataLabels_show to false and shared_tooltip to true
    const data = cloneDeep(prevState.calculations || []);
    const rowIndex = data?.findIndex(
      (d) =>
        d.valueId === updatedRow.valueId &&
        d.valueType === updatedRow.valueType &&
        d.operation === updatedRow.operation
    );

    if (rowIndex > -1) {
      data[rowIndex] = updatedRow;
    } else {
      data[data.length] = updatedRow;
    }

    return {
      ...prevState,
      customization: null,
      calculations: data,
    };
  });
};

const getDataOnAggregateScope = (dataItems, calculationsObject, data) => {
  return dataItems.reduce((d, dataItem) => {
    const data =
      calculationsObject[dataItem.valueType] &&
      calculationsObject[dataItem.valueType][dataItem.valueId] &&
      calculationsObject[dataItem.valueType][dataItem.valueId][dataItem.operation];

    return {
      calculations: [
        ...d.calculations,
        {
          valueType: dataItem.valueType,
          valueId: dataItem.valueId,
          expression: dataItem.valueType === 'CALCULATION' ? '0' : '',
          operation: dataItem.operation,
          ...(data || {}),
          order: d.counter,
        },
      ],
      counter: d.counter + 1,
    };
  }, data);
};

const getDataOnLastValueScope = (calculationsObject, data, dataItem, widgetData) => {
  const { type } = widgetData;
  const calculationsObjectType = dataItem.valueType || dataItem.tagType;

  const id = dataItem.valueId || dataItem.id || 0;
  const dataObj =
    calculationsObject[calculationsObjectType] && calculationsObject[calculationsObjectType][id];
  const isNumber = ['numberType', 'floatType'].includes(dataItem?.format || dataItem?.type);

  return {
    calculations: [
      ...data.calculations,
      isNumber
        ? {
            valueType: dataItem.valueType || dataItem.tagType,
            valueId: dataItem.valueId || dataItem.id,
            expression: dataItem.valueType === 'CALCULATION' ? '0' : '',
            operation:
              widgetData?.type?.toLowerCase() === 'pie' && widgetData.scope === 'LAST_VALUE'
                ? 'SUM'
                : null,
            ...(type === 'pie' ? dataObj?.SUM : dataObj?.null || {}),
            order: data.counter,
          }
        : dataItem.valueType === 'CALCULATION' && {
            valueType: dataItem.valueType,
            valueId: dataItem.valueId,
            expression: calculationsObject.CALCULATION[dataItem.valueId]?.null?.expression,
            operation:
              widgetData?.type?.toLowerCase() === 'pie' && widgetData.scope === 'LAST_VALUE'
                ? 'SUM'
                : null,
            order: data.counter,
            metrics: dataObj.null.metrics,
          },
    ],
    counter: data.counter + (isNumber ? 1 : 0),
  };
};

export const buildOptions = (option, type, scope) => {
  const tagFormat =
    type === 'VARIABLE'
      ? variableFormatMap[option.valueType]
      : type === 'TAG'
      ? tagFormatMap[option.format]
      : tagFormatMap[option.type];
  return {
    id: option.id,
    tagType: type,
    type: tagFormat,
    name: option.name,
  };
};

export const getCalculationItems = (widgetData, setCalculationItems, calculationsObject) => {
  const { metrics, scope, customizationMetrics } = widgetData as any;
  const dataArray = concat(metrics, customizationMetrics);
  const data = dataArray.reduce(
    (data: any, dataItem: any) => {
      if (scope === 'AGGREGATED_DATA' || scope === 'AGGREGATED_LAST_VALUE') {
        let dataItems = [dataItem];
        return getDataOnAggregateScope(dataItems, calculationsObject, data);
      } else {
        return getDataOnLastValueScope(calculationsObject, data, dataItem, widgetData);
      }
    },
    { calculations: [], counter: 0 }
  );
  setCalculationItems(compact(data.calculations));
};

export function getDataSourcesWithDataType(dataSources: any[], dataType: string) {
  return dataSources?.map((ds) => ({ ...ds, dataType: dataType })) || [];
}
