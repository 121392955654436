import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import Button from '@components/Button';
import Icon from '@components/Icon';
import I18n from '@components/I18n';
import styles from './VariableInUseModal.scss';
import classnames from 'classnames';
import { useSelector } from '@redux/useSelector';
import { i18nService } from '@core/i18n/I18nService';
import {
  arProjectUsageCfg,
  dashboardUsageCfg,
  eventTemplateUsageCfg,
} from './VariableInUseModal.utils';
import AccordionWithTable from '@components/AccordionWithTable/AccordionWithTable';

function VariableInUseModal(props) {
  const { args, dismiss } = props;
  const { variableName, dashboardUsage, eventTemplateUsage, arProjectUsage } = args;
  const [data, setData] = useState<Array<{ title: string; config: any; data: any[] }>>([]);

  const defaultExpanded =
    [dashboardUsage?.length, eventTemplateUsage?.length, arProjectUsage?.length].filter(
      (item) => item > 0
    )?.length === 1;

  const confirmBtn = useRef(null);
  const close = useCallback(() => dismiss(undefined), [dismiss]);
  const isMobileView = useSelector((state) => state.viewport.isMobileView);

  useEffect(() => {
    confirmBtn.current.focus();
  }, []);

  const dashboards = useMemo(() => {
    let dashboards = [];

    if (dashboardUsage.length) {
      dashboards = dashboardUsage
        .sort((a, b) =>
          a.dashboardName === b.dashboardName && a.widgetName > b.widgetName ? -1 : 1
        )
        .sort((a, b) => (a.dashboardName.toLowerCase() > b.dashboardName.toLowerCase() ? 1 : -1))
        .reduce(
          (prev, cur) => [
            ...prev,
            {
              dashboardName: cur.dashboardName,
              dashboardStatus: `${i18nService.translate(`enum.${cur.dashboardStatus}`)}`,
              widgetName: cur.widgetName,
              widgetType: `${i18nService.translate(
                `edit-dashboard.widget-name.${cur.widgetType.toLowerCase()}`
              )}`,
            },
          ],
          []
        );
    }
    return dashboards;
  }, []);

  const eventTemplates = useMemo(() => {
    let eventTemplates = [];

    if (eventTemplateUsage.length > 0) {
      const eventTemplateUsageWithDetails = eventTemplateUsage.map((item) => {
        if (item.trigger) {
          return {
            ...item,
            details: `${i18nService.translate('templates-delails-modal.trigger')}`,
          };
        } else if (item.widgetName) {
          return {
            ...item,
            details: `${i18nService.translate('general.data-source')} - ${i18nService.translate(
              `edit-dashboard.widget-name.${item.widgetType.toLowerCase()}`
            )}: ${item.widgetName}
            `,
          };
        }
      });
      eventTemplates = eventTemplateUsageWithDetails
        .sort((a, b) => (a.templateName.toLowerCase() > b.templateName.toLowerCase() ? 1 : -1))
        .reduce(
          (prev, cur) => [
            ...prev,
            {
              templateName: cur.templateName,
              details: cur.details,
            },
          ],
          []
        );
    }
    return eventTemplates;
  }, []);

  const arProjects = useMemo(() => {
    let arProjects = [];

    if (arProjectUsage.length) {
      arProjects = arProjectUsage
        .sort((a, b) =>
          a.arProjectName === b.arProjectName && a.widgetName > b.widgetName ? -1 : 1
        )
        .sort((a, b) => (a.arProjectName.toLowerCase() > b.arProjectName.toLowerCase() ? 1 : -1))
        .reduce(
          (prev, cur) => [
            ...prev,
            {
              arProjectName: cur.arProjectName,
              arProjectStatus: `${i18nService.translate(`enum.${cur.arProjectStatus}`)}`,
              widgetName: cur.widgetName,
              widgetType: `${i18nService.translate(
                `edit-dashboard.widget-name.${cur.widgetType.toLowerCase()}`
              )}`,
            },
          ],
          []
        );
    }
    return arProjects;
  }, []);

  useEffect(() => {
    setData([
      {
        title: 'general.dashboards',
        config: dashboardUsageCfg,
        data: dashboards,
      },
      {
        title: 'general.event-templates',
        config: eventTemplateUsageCfg,
        data: eventTemplates,
      },
      {
        title: 'general.ar-projects',
        config: arProjectUsageCfg,
        data: arProjects,
      },
    ]);
  }, [dashboards, eventTemplates, arProjects]);

  return (
    <div className={classnames(styles.wrapper, isMobileView && styles.wrapperMobile)}>
      <div className={styles.modalHeader}>
        <I18n className={styles.modalHeader} element="div">
          variables.variable-in-use.header
        </I18n>
        <Icon type="close" onClick={close} className={'pointer'}></Icon>
      </div>
      <div className={styles.content}>
        <I18n className={styles.variableNameTitle} style={{ marginRight: '10px' }}>
          variables.variable-in-use.name
        </I18n>
        {variableName}
        <div className={styles.tableWrapper}>
          {data.length
            ? data.map((item, idx) =>
                item?.data?.length ? (
                  <AccordionWithTable
                    key={idx}
                    title={item.title}
                    columnsCfg={item.config}
                    tableData={item.data}
                    defaultExpanded={defaultExpanded}
                  />
                ) : null
              )
            : null}
        </div>
      </div>
      <div className={styles.modalButtons}>
        <Button onClick={close} styles={{ marginLeft: 13 }} mode="bigFont" ref={confirmBtn}>
          <I18n>general.close</I18n>
        </Button>
      </div>
    </div>
  );
}

export default VariableInUseModal;
