import React, { useState, useEffect } from 'react';
import classNames from 'classnames';
import Icon from '@components/Icon';
import I18n from '@components/I18n';
import Tooltip from '@components/Tooltip';
import styles from './Metrics.scss';
import { Draggable, Droppable } from 'react-beautiful-dnd';
import { options } from '@pages/CreateWidgetPage/SharedMaps';
import { getConfigValueFromWidgetSettings } from '@core/canvas/widget.utils';
import { useCalculations } from './Calculations/useCalculations';
import MetricsTagComponent from './MetricsTagComponent';
import { modalService } from '@core/modals/ModalService';
import { useAddLocalTag } from './useAddLocalTag';
import { singleValueWidgets } from '@core/mapsAndDefinitions';
import IconButtonWithText from '@components/IconButtonWithText';
import { i18nService } from '@core/i18n/I18nService';
import { getNextNumberInArray, getUniqueNameWithIncrement } from '@core/utils';

function Metrics(props) {
  const { setWidgetData, widgetData, availableData, metrics, scope, type, disabled } = props;
  const { localTags = [] } = widgetData;
  const { calculationItems } = useCalculations({ widgetData, setWidgetData });
  const [metricsSelected, setMetricsSelected] = useState(metrics);
  const extraMetricsText = getConfigValueFromWidgetSettings(type, 'extraMetricsText');
  const { addLocalTagArgs } = useAddLocalTag({
    widgetData,
    setWidgetData,
    groupBy: widgetData.groupBy,
    metrics,
  });

  useEffect(() => {
    const metricsWithCalculations = metrics.map((m) => {
      const matchingCalculation =
        calculationItems.find(
          (ci) =>
            ci.valueType === m.valueType && ci.valueId === m.valueId && ci.operation === m.operation
        ) || null;
      return { ...m, calculation: matchingCalculation };
    });
    setMetricsSelected(metricsWithCalculations);
  }, [metrics, calculationItems, availableData]);

  function openAddLocalTagModal() {
    const id = getNextNumberInArray(localTags, 'id');

    const initialValues = {
      id: null,
      name: getUniqueNameWithIncrement(
        localTags,
        'name',
        `${i18nService.translate('create-widget-page.create-widget.step-three.local-tag')}`,
        id
      ),
      format: 'FLOAT32',
    };

    modalService.openModal('formikFormModal', {
      ...addLocalTagArgs,
      initialValues,
    });
  }

  return (
    <>
      <div className={styles.sectionHeader}>
        <div className={styles.infoGroup}>
          <I18n className={styles.subTitle}>
            create-widget-page.create-widget.step-three.data-and-metrics
          </I18n>
          <Tooltip
            text={'create-widget-page.create-widget.step-three.data-and-metrics-info'}
            placement="right"
            children={
              <div>
                <Icon className={styles.iconInfo} type="info"></Icon>
              </div>
            }></Tooltip>
        </div>
        {[
          'conditional_value',
          'image_by_value',
          'progress_bar',
          'value',
          'table',
          'columns',
          'radial_gauge',
          'linear_gauge',
          'line',
          'pie',
          'asset',
          'heatmap',
        ].includes(type) && (
          <IconButtonWithText
            iconType="create"
            name={'create-widget-page.create-widget.step-three.add-local-tag'}
            showName={true}
            tooltip={'create-widget-page.create-widget.step-three.add-local-tag'}
            onClick={openAddLocalTagModal}
            isDisabled={
              (singleValueWidgets.includes(type) && metrics?.length) ||
              (type === 'asset' && metrics?.length >= 8) ||
              (type === 'heatmap' && widgetData.groupBy?.length > 1 && metrics?.length)
            }
            styles={{ textMargin: '4px 5px 2px 5px', fontSize: '12px' }}
          />
        )}
      </div>
      <Droppable droppableId="metrics" isDropDisabled={disabled}>
        {(provided) => (
          <div
            className={classNames('default', styles.metricsBox)}
            ref={provided.innerRef}
            {...provided.droppableProps}>
            {metricsSelected.filter((m) => !m.hide).length === 0 ? (
              <>
                <I18n noEllipsis className={styles.boxText}>
                  create-widget-page.create-widget.step-three.data-and-metrics-box
                </I18n>
                {extraMetricsText?.condition() && (
                  <div>
                    <I18n noEllipsis className={styles.boxText}>
                      {extraMetricsText.value(scope, widgetData.eventTemplateId)}
                    </I18n>
                  </div>
                )}
              </>
            ) : (
              metricsSelected.map((metricsTag, idx) =>
                metricsTag.hide ? null : (
                  <Draggable
                    key={`${metricsTag.order}metrics`}
                    draggableId={`${metricsTag.order}metrics`}
                    index={idx}>
                    {(provided) => (
                      <div
                        className={styles.metricsItem}
                        ref={provided.innerRef}
                        {...provided.draggableProps}>
                        <div className={styles.metricItem}>
                          <div className={styles.dragIndicator} {...provided.dragHandleProps}>
                            <Icon type="drag" />
                          </div>
                          <MetricsTagComponent
                            metricsTag={metricsTag}
                            availableData={availableData}
                            idx={idx}
                            scope={scope}
                            widgetType={type}
                            operations={options}
                            widgetData={widgetData}
                            setWidgetData={setWidgetData}
                            metricsSelected={metricsSelected}
                            setMetricsSelected={setMetricsSelected}
                          />
                        </div>
                      </div>
                    )}
                  </Draggable>
                )
              )
            )}
            {provided.placeholder}
          </div>
        )}
      </Droppable>
    </>
  );
}
export default Metrics;
