import { httpService } from '@core/http/HttpService';
import { modalService } from '@core/modals/ModalService';
import { getVariablesList, origin } from '../../Variables.utils';

export function openEditVariableSettingsModal(
  origin: origin,
  variable: any,
  setVariablesList: any
) {
  modalService.openModal('editVariableSettingsModal', {
    origin,
    variable,
    setVariablesList,
  });
}

export async function openDeleteVariableModal(
  origin: origin,
  assetTypeId: number,
  variableId: number,
  variableName: string,
  setVariablesList: any
) {
  modalService
    .openModal('confirm', {
      text: 'variables.delete-variable-pop-up.text',
      iconType: 'attention_image',
      confirmText: 'general.confirm',
      cancelText: 'general.cancel',
      headerText: 'variables.delete-variable-pop-up.header',
      showCloseBtn: true,
      variableName,
    })
    .then(async (confirm) => {
      if (confirm) {
        const res = await checkVariableInUse(variableId);
        const isVariablesInUse =
          res.dashboardUsage.length > 0 ||
          res.eventTemplateUsage.length > 0 ||
          res.arProjectUsage.length > 0;
        if (!isVariablesInUse) {
          deleteVariable(origin, assetTypeId, variableId, setVariablesList);
        } else {
          openVariableInUseModal(res, variableName);
        }
      }
    });
}

function openVariableInUseModal(res, variableName) {
  modalService.openModal('variableInUseModal', {
    headerText: 'dashboard-gallery.delete-dashboard.published-header',
    dashboardUsage: res.dashboardUsage,
    eventTemplateUsage: res.eventTemplateUsage,
    arProjectUsage: res.arProjectUsage,
    variableName: variableName,
  });
}

export async function deleteVariable(
  origin,
  assetTypeId,
  variableId: number,
  setVariablesList: any
) {
  httpService
    .api({
      type: 'deleteVariable',
      urlParams: { variableId: variableId },
    })
    .then(() => {
      getVariablesList(origin, assetTypeId, setVariablesList);
    });
}

async function checkVariableInUse(variableId) {
  const res: any = await httpService.api({
    type: 'getVariableInUse',
    urlParams: { variableId },
  });

  return res;
}
