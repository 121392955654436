import { compact } from 'lodash';
import {
  convertCalculationToObject,
  getIsFloat,
  getMetricName,
} from '@pages/CreateWidgetPage/widgets.utils';
import { getVar } from '@core/canvas/widget.utils';
import { i18nService } from '@core/i18n/I18nService';

export const changeWidgetData = (value, property: string, setWidgetData) => {
  switch (property) {
    case 'tooltip_decimalDigits':
    case 'dataLabels_decimalDigits':
      const reg = /^\d+$/;
      if ((!reg.test(value.toString()) && value.toString() !== '') || +value > 9) return;
      break;
    case 'columnsWidthPercent':
      if (+value < 1 || +value > 100) return;
      break;
  }
  setWidgetData((prevState) => ({
    ...prevState,
    customization: {
      ...prevState.customization,
      [property]: value,
    },
  }));
};

const getColDefaultColor = (colIndex) => {
  const varIndex = colIndex < 6 ? colIndex : colIndex % 6;
  return getVar(`widgetsGraphsColorsPalette${varIndex + 1}`);
};

const getDefaultMetric = (metric, name, idx = 0) => ({
  id: metric.valueId,
  valueType: metric.valueType,
  operation:
    metric.operation && name.includes(` ${metric.operation?.toLowerCase()}`)
      ? metric.operation
      : null,
  name,
  displayName: name.length > 20 ? name.slice(0, 20) : name,
  color: getColDefaultColor(idx),
});

function getDefaultYAxis(yAxes, metric, name, idx, isFloat: boolean, defaultDecimalDigits = 0) {
  const decimalDigits =
    yAxes.find((yAxis) => yAxis.valueId === metric.valueId && yAxis.valueType === metric.valueType)
      ?.decimalDigits || defaultDecimalDigits;

  return {
    id: metric.valueId,
    valueType: metric.valueType,
    operation:
      metric.operation && name.includes(` ${metric.operation?.toLowerCase()}`)
        ? metric.operation
        : null,
    name,
    displayName: name.length > 20 ? name.slice(0, 20) : name,
    show: idx === 0 ? 'LEFT' : 'NO',
    scale: 'AUTO',
    maxValue: null,
    minValue: null,
    decimalDigits: isFloat ? decimalDigits : null,
  };
}

const getDataOnAggregateScope = (
  metric,
  data,
  calculationsObject,
  widgetData,
  defaultDecimalDigits
) => {
  const metricName = getMetricName(widgetData, metric);

  const name = metric.operation
    ? `${i18nService.translate(metricName)} ${metric.operation.toLowerCase()}`
    : metricName;

  const calculation =
    calculationsObject[metric.valueType] &&
    calculationsObject[metric.valueType][metric.valueId] &&
    calculationsObject[metric.valueType][metric.valueId][metric.operation];

  const isFloat = getIsFloat(
    metric.operation,
    metric.valueType,
    metric.type,
    calculation,
    widgetData
  );
  return {
    showDecimalPoint: data.showDecimalPoint || isFloat,
    columns: [...data.columns, getDefaultMetric(metric, name, data.counter)],
    yAxes: [
      ...data.yAxes,
      getDefaultYAxis(data.yAxes, metric, name, data.counter, isFloat, defaultDecimalDigits),
    ],
    counter: data.counter + 1,
  };
};

const getDataOnRawDataScope = (
  data,
  metric,
  idx,
  calculationsObject,
  widgetData,
  defaultDecimalDigits
) => {
  const metricName = getMetricName(widgetData, metric);

  const calculation =
    calculationsObject[metric?.valueType || metric?.tagType] &&
    calculationsObject[metric.valueType || metric.tagType][metric.valueId || metric.id];

  const isFloat = getIsFloat(
    null,
    metric.valueType,
    metric.type,
    calculation?.null || calculation,
    widgetData
  );

  return {
    showDecimalPoint: data.showDecimalPoint || isFloat,
    columns: [...data.columns, getDefaultMetric(metric, metricName, idx)],
    yAxes: [
      ...data.yAxes,
      getDefaultYAxis(data.yAxes, metric, metricName, idx, isFloat, defaultDecimalDigits),
    ],
    counter: data.counter + 1,
  };
};

export const setColumnsDefaultData = (
  customization,
  widgetData,
  previewData,
  setWidgetData,
  defaultDecimalDigits
) => {
  let defaultData;
  const calculationsObject = convertCalculationToObject(widgetData.calculations);

  defaultData = widgetData.metrics.reduce(
    (data: any, metric: any, idx) => {
      if (metric.operation) {
        return getDataOnAggregateScope(
          metric,
          data,
          calculationsObject,
          widgetData,
          defaultDecimalDigits
        );
      } else {
        return getDataOnRawDataScope(
          data,
          metric,
          idx,
          calculationsObject,
          widgetData,
          defaultDecimalDigits
        );
      }
    },
    { columns: [], yAxes: [], counter: 0, showDecimalPoint: false }
  );

  let { columns, yAxes } = defaultData;

  if (customization?.columns?.length) {
    columns = columns.map((originalItem) => {
      const existingItem = customization.columns.find(
        (item) => originalItem.id === item.id && originalItem.operation === item.operation
      );
      return existingItem ? { ...existingItem, name: originalItem?.name } : originalItem;
    });
  }

  if (customization?.yAxes?.length) {
    yAxes = yAxes.map((originalItem) => {
      const existingItem = customization.yAxes.find(
        (item) => originalItem.id === item.id && originalItem.operation === item.operation
      );
      return existingItem ? { ...existingItem, name: originalItem?.name } : originalItem;
    });
  }

  setWidgetData((prevState) => ({
    ...prevState,
    customization: {
      ...prevState.customization,
      columns: columns.sort((item1, item2) => item1.order - item2.order),
      yAxes,
    },
  }));
};

export const changeWidgetTable = (columnUpdated, index, tableKey, setWidgetData) => {
  setWidgetData((prevState) => ({
    ...prevState,
    customization: {
      ...prevState.customization,
      [tableKey]: prevState.customization[tableKey].map((col, idx) =>
        idx === index ? columnUpdated : col
      ),
    },
  }));
};

export const yAxesCfg = () =>
  compact([
    {
      style: { paddingRight: '10px', minWidth: '20%', width: '20%' },
      headerLabel: 'create-widget-page.create-widget.step-four.columns.tag-name',
    },
    {
      style: { paddingRight: '10px', minWidth: '15%', width: '15%' },
      headerLabel: 'create-widget-page.create-widget.step-four.columns.show-y-axis',
    },
    {
      style: { paddingRight: '10px', minWidth: '17%', width: '17%' },
      headerLabel: 'create-widget-page.create-widget.step-four.columns.y-axes-name',
    },
    {
      style: { paddingRight: '10px', minWidth: '12%', width: '12%' },
      headerLabel: 'create-widget-page.create-widget.step-four.columns.y-axes-decimal-digits',
    },
    {
      style: { paddingRight: '10px', minWidth: '12%', width: '15%' },
      headerLabel: 'create-widget-page.create-widget.step-four.columns.y-axes-scale',
    },
    {
      style: { paddingRight: '10px', minWidth: '11%', width: '11%' },
      headerLabel: 'create-widget-page.create-widget.step-four.columns.min-value',
    },
    {
      style: { paddingRight: '10px', minWidth: '11%', width: '11%' },
      headerLabel: 'create-widget-page.create-widget.step-four.columns.max-value',
    },
  ]);

export const tagSettingsCfg = compact([
  {
    style: { minWidth: '30%', width: '30%', paddingRight: '10px' },
    headerLabel: 'create-widget-page.create-widget.step-four.columns.tag-name',
  },
  {
    style: { minWidth: '10%', width: '10%' },
  },
  {
    style: { minWidth: '30%', width: '30%', paddingRight: '10px' },
    headerLabel: 'create-widget-page.create-widget.step-four.display-name',
  },
  {
    style: { minWidth: '10%', width: '10%' },
  },
  {
    type: 'colorInput',
    field: 'color',
    style: { minWidth: '20%', width: '20%' },
    headerLabel: 'create-widget-page.create-widget.step-four.color',
  },
]);

export const thresholdCfg = compact([
  {
    style: { minWidth: '10%', width: '10%', paddingRight: '10px' },
    headerLabel: 'general.show',
  },
  {
    style: { minWidth: '27%', width: '27%', paddingRight: '10px' },
    headerLabel: 'create-widget-page.create-widget.step-four.display-name',
  },
  {
    style: { minWidth: '26%', width: '26%', paddingRight: '10px' },
    headerLabel: 'create-widget-page.create-widget.step-four.value',
  },
  {
    style: { minWidth: '27%', width: '27%', paddingRight: '10px' },
    headerLabel: 'create-widget-page.create-widget.step-four.style',
  },
  {
    style: { minWidth: '10%', width: '10%' },
    headerLabel: 'create-widget-page.create-widget.step-four.color',
  },
]);

export const legendCfg = compact([
  {
    type: 'dragIndicator',
    style: { minWidth: '5%', width: '5%', marginRight: '5%' },
  },
  {
    type: 'checkbox',
    field: 'show',
    style: { minWidth: '30%', width: '30%' },
    headerLabel: 'general.show',
  },
  {
    type: 'label',
    field: 'label',
    style: { minWidth: '60%', width: '60%' },
    headerLabel: 'general.item',
  },
]);

export const legendLabels = {
  assetId: 'create-widget-page.create-widget.asset-properties.ASSET_ID',
  assetName: 'create-widget-page.create-widget.asset-properties.ASSET_NAME',
  tagName: 'create-widget-page.create-widget.step-four.tag-name',
};

export const onLegendRowChanged = (value, editedRow, name, setWidgetData) => {
  setWidgetData((prevState) => {
    return prevState.map((row) => {
      if (row.item === editedRow.item) {
        return { ...row, [name]: value };
      }
      return row;
    });
  });
};

export function onTagSettingsRowChanged(setWidgetData, tableColumns) {
  setWidgetData((prevState) => ({
    ...prevState,
    customization: {
      ...prevState.customization,
      columns: [...tableColumns],
    },
  }));
}
