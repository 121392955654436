import React, { useEffect, useMemo, useState } from 'react';
import styles from './SystemSettings.scss';
import { Formik, Form as FormikForm } from 'formik';
import FormikField from '@components/FormikField';
import classNames from 'classnames';
import Button from '@components/Button';
import { httpService } from '@core/http/HttpService';
import I18n from '@components/I18n';
import moment from 'moment';
import Icon from '@components/Icon';
import { useSelector } from '@src/redux/useSelector';
import organizationTypeMap from '@pages/OrganizationsPage/OrganizationTypeMap';
import { modalService } from '@core/modals/ModalService';

const SYSTEM_SETTING_ENUM = {
  DEMO_DATA_FOLDER: 1,
  EVENT_SETTINGS: 2,
  WHAT_FIX: 3,
  WELCOME_EMAIL_INTERNATIONAL: 4,
  WELCOME_EMAIL_US: 5,
  WELCOME_EMAIL_FR: 6,
  INITIAL_DASHBOARD_REF_ID: 7,
  SEND_DAILY_REPORTS: 8,
  ALLOW_VPN_WIDGET: 9,
  ALLOW_SIGN_UP: 10,
  SMS_NOTIFICATIONS_BLOCKED: 11,
  BACKUP_RESTORE_DURATION: 12,
};

function SystemSettings() {
  const [editMode, setEditMode] = useState(false);
  const [data, setData] = useState({});
  const [saving, setSaving] = useState(false);
  const organizationDetails = useSelector((state) => state.config.organizationDetails);
  const isUnitronicsAdminUser = organizationTypeMap[organizationDetails?.type] === 1;

  const fields = useMemo(
    () => [
      {
        label: 'Send daily reports',
        name: 'sendDailyReports',
        type: 'checkbox',
        labelStyle: { width: 200, height: '36px' },
        editLabelStyle: { width: 200, height: '20px' },
      },
      {
        isRequired: true,
        label: 'Notifications expiration days',
        name: 'notificationStoreFileDuration',
        type: 'input',
        labelStyle: { width: 200, height: '36px', marginBottom: 0 },
        editLabelStyle: { width: 200, height: '20px', marginBottom: 0 },
        validate: (values) => {
          if (
            values['storeFileDuration'] &&
            (parseInt(values['storeFileDuration']) < 1 || isNaN(values['storeFileDuration']))
          ) {
            return 'my-details.notifications_expiration_len';
          }
        },
      },
      {
        isRequired: true,
        label: 'Backup expiration days',
        name: 'backupStoreFileDuration',
        type: 'input',
        labelStyle: { width: 200, height: '36px', marginBottom: 0 },
        editLabelStyle: { width: 200, height: '20px', marginBottom: 0 },
        validate: (values) => {
          if (
            values['storeFileDuration'] &&
            (parseInt(values['storeFileDuration']) < 1 || isNaN(values['storeFileDuration']))
          ) {
            return 'my-details.notifications_expiration_len';
          }
        },
      },
      {
        label: 'Whatfix enabled',
        name: 'whatFixEnabled',
        type: 'checkbox',
        labelStyle: { width: 200, height: '36px' },
        editLabelStyle: { width: 200, height: '20px' },
      },
      {
        isRequired: true,
        label: 'Script to load',
        name: 'whatFixScript',
        type: 'input',
        labelStyle: { width: 200, height: '36px' },
        editLabelStyle: { width: 200, height: '20px' },
      },
    ],
    []
  );

  useEffect(() => {
    getExpiration();
  }, []);

  function getFieldValue(
    settings: any,
    type: 'EVENT_SETTINGS' | 'WHAT_FIX' | 'SEND_DAILY_REPORTS' | 'BACKUP_RESTORE_DURATION',
    field: string
  ) {
    return settings.find((s) => s.type === type).value[field];
  }

  const getExpiration = async () => {
    try {
      const res = await httpService.api({
        type: 'getSystemSettings',
      });
      if (res) {
        setData((prev) => ({
          ...prev,
          notificationStoreFileDuration: getFieldValue(res, 'EVENT_SETTINGS', 'storeFileDuration'),
          backupStoreFileDuration: getFieldValue(
            res,
            'BACKUP_RESTORE_DURATION',
            'storeFileDuration'
          ),
          whatFixEnabled: getFieldValue(res, 'WHAT_FIX', 'whatFixEnabled'),
          whatFixScript: getFieldValue(res, 'WHAT_FIX', 'whatFixScript'),
          sendDailyReports: getFieldValue(res, 'SEND_DAILY_REPORTS', 'sendDailyReports'),
        }));
      }
    } catch {}
  };

  const onClick = () => {
    httpService
      .api({
        type: 'downloadUsersCsv',
      })
      .then((res: any) => {
        const fileName = `UniCloud users - ${moment().format('D_M_YYYY')}.csv`;
        if (navigator['msSaveBlob']) {
          // IE 10+
          const blob = new Blob([res], { type: '' });

          const msSaveBlob = navigator['msSaveBlob'];
          msSaveBlob && msSaveBlob(blob, fileName);
        } else {
          //for downloading csv we cant use the regular way
          const csvData = new Blob([res], { type: 'text/csv' });
          const csvUrl = URL.createObjectURL(csvData);
          const downloadLink = document.createElement('a');

          downloadLink.href = csvUrl;
          downloadLink.download = fileName;
          downloadLink.click();
        }
      });
  };

  const createDailyReport = () => {
    httpService.api({
      type: 'createDailyReport',
    });
  };

  const createAssetsAndPlcsReport = () => {
    httpService.api({
      type: 'createAssetsAndPlcsReport',
    });
  };

  const handleMaintenanceButton = () => {
    modalService
      .openModal('customDateModal', {
        confirmText: 'Confirm',
        cancelText: 'Cancel',
        showCloseBtn: true,
        headerText: 'Maintenance Scheduled Date',
        customText: 'Pick Scheduled Maintenance Date',
        style: { width: '360px' },
        icon: 'alert',
      })
      .then((dateValue) => {
        if (dateValue) {
          handleSelectedDate(dateValue);
        }
      });
  };

  const handleSelectedDate = (dateValue) => {
    return modalService
      .openConfirm({
        text: `Are you sure you want to send a maintenance email to Main contacts, notifying them of the scheduled maintenance on ${dateValue}?`,
        headerText: 'Confirm Maintenance Notification',
        showCloseBtn: true,
      })
      .then((confirmed) => {
        if (confirmed) {
          sendMaintenanceNotification(dateValue);
        }
      });
  };

  const sendMaintenanceNotification = (dateValue) => {
    httpService.api({
      type: 'sendMaintenanceNotificationEmails',
      data: { dateOfMaintenance: dateValue },
    });
  };

  const onSubmit = async (values) => {
    try {
      const { whatFixEnabled, whatFixScript } = values;
      setSaving(true);
      setEditMode(!editMode);
      setData((prev) => ({ ...prev, ...values }));

      const res = await httpService.api({
        type: 'setSystemSettings',
        data: {
          [SYSTEM_SETTING_ENUM.EVENT_SETTINGS]: {
            storeFileDuration: +values.notificationStoreFileDuration,
          },
          [SYSTEM_SETTING_ENUM.WHAT_FIX]: {
            whatFixEnabled,
            whatFixScript,
          },
          [SYSTEM_SETTING_ENUM.SEND_DAILY_REPORTS]: { sendDailyReports: values.sendDailyReports },
          [SYSTEM_SETTING_ENUM.BACKUP_RESTORE_DURATION]: {
            storeFileDuration: +values.backupStoreFileDuration,
          },
        },
      });

      if (res) {
        setSaving(false);
      }
    } catch (e) {}
  };

  const validate = (values) => {
    return fields.reduce(
      (res, item: any) =>
        item.validate && item.validate(values)
          ? { ...res, [item.name]: item.validate(values) }
          : res,
      {}
    );
  };

  return (
    <>
      <Button styles={{ marginLeft: 18, marginBottom: 18 }} mode="big" onClick={onClick}>
        Download Users
      </Button>
      {isUnitronicsAdminUser && (
        <>
          <Button
            styles={{ marginLeft: 18, marginBottom: 18 }}
            mode="big"
            onClick={createDailyReport}>
            Daily report
          </Button>
          <Button
            styles={{ marginLeft: 18, marginBottom: 18 }}
            mode="big"
            onClick={createAssetsAndPlcsReport}>
            Assets and PLCs
          </Button>
          {/* <Button
            styles={{ marginLeft: 18, marginBottom: 18 }}
            mode="big"
            onClick={handleMaintenanceButton}>
            Maintenance Notification
          </Button> */}
        </>
      )}
      <Formik
        enableReinitialize
        initialValues={{ ...data, enable: editMode }}
        validate={validate}
        onSubmit={onSubmit}>
        {({ isValid, values, setFieldValue }) => {
          return (
            <FormikForm className={styles.form}>
              <div className={styles.box} style={{ marginTop: editMode ? '11px' : 0 }}>
                {!editMode && (
                  <Icon
                    type="edit"
                    color="var(--systemFont)"
                    className={classNames('pointer', styles.editBtn)}
                    onClick={() => setEditMode(!editMode)}
                  />
                )}
                <div className={classNames(styles.details, editMode && styles.editMode)}>
                  <div className={classNames(styles.boxBody, styles.spaceBox)}>
                    {fields.map((field) => (
                      <FormikField
                        editMode={editMode}
                        key={field.name}
                        {...field}
                        values={values}
                        setFieldValue={setFieldValue}
                      />
                    ))}
                  </div>
                </div>
              </div>
              {editMode && (
                <div className={styles.footerButtons}>
                  <Button
                    styles={{ width: 92, marginRight: 13, backgroundColor: '#c2cfe0' }}
                    onClick={() => setEditMode(false)}>
                    <I18n>general.cancel</I18n>
                  </Button>
                  <Button styles={{ width: 92 }} type="submit" disabled={!isValid || saving}>
                    <I18n>general.save</I18n>
                  </Button>
                </div>
              )}
            </FormikForm>
          );
        }}
      </Formik>
    </>
  );
}

export default SystemSettings;
